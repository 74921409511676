import React, { useState, useEffect, useRef, useCallback, useMemo } from "react";
import { useSearchParams, useNavigate } from 'react-router-dom';
import { Select, Button, Alert, Typography, Badge, Skeleton, Modal, Input, Dropdown, Menu, List, Tooltip, Row, Col, Divider } from 'antd';
import { formatTimeAgo, uuid4, volumeFormatter } from '../utils';
import { faWandMagicSparkles } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import dayjs from "dayjs";

import {
    DeleteOutlined,
    FunctionOutlined,
    CloseCircleFilled,
    ArrowLeftOutlined,
    EditOutlined,
    CaretDownOutlined,
    LoadingOutlined,
    UndoOutlined,
    WarningTwoTone,
    FontSizeOutlined,
    FieldBinaryOutlined,
    DatabaseOutlined,
    PlayCircleOutlined,
    CloudDownloadOutlined,
    CloudUploadOutlined,
    SyncOutlined,
    FilterOutlined,
    ArrowUpOutlined,
    ArrowDownOutlined,
    CloseCircleOutlined,
    ImportOutlined,
    DownloadOutlined
} from "@ant-design/icons";
import DataGrid from './DataGrid';
import { fetchWorksheetData } from "../../endpoints/fetchWorksheetData";
import { fetchThreadsData } from "../../endpoints/fetchThreadsData";
import {EnrichmentFlow} from "./EnrichmentFlow";
import ExportFlow from "./ExportFlow";
import ImportFlow from "./ImportFlow";

const colorPrimary = '#4fad96';


function CustomEditor({ row, column, onRowChange, onClose }) {
    const [val, setVal] = useState(row[column.key]);

    function commitEdit() {
        onClose(true);
    }

    return (
        <div
            className="rdg-editor-container"
            style={{ display: 'flex', alignItems: 'center', height: '100%' }}
        >
            <input
                autoFocus
                className="rdg-text-editor"
                style={{ width: '100%', height: '100%', boxSizing: 'border-box', padding: '0 8px' }}
                value={val}
                onChange={e => {
                    const newValue = e.target.value;
                    setVal(newValue);
                    
                    // 1) Build the updated row with the new cell value
                    const updated = { ...row, [column.key]: newValue };
                    
                    // 2) If column is enrichment or has data_type==='object', mark override
                    if (
                      column.column_type === 'enrichment'
                      || column.data_type === 'object'
                    ) {
                        if (newValue && newValue !== '') {
                            updated._overrides = updated._overrides || {};
                            updated._overrides[column.key] = true;
                        } else {
                            if (updated._overrides) {
                                delete updated._overrides[column.key];
                                if (Object.keys(updated._overrides).length === 0) {
                                    delete updated._overrides;
                                }
                            }
                        }
                    }

                    onRowChange(updated);
                }}
                onBlur={commitEdit}
                onKeyDown={(e) => {
                    if (e.key === 'Enter') commitEdit();
                }}
            />
        </div>
    );
}

const colTypeToIcon = {
    text: <FontSizeOutlined style={{color: '#918d8d'}} />,
    number: <FieldBinaryOutlined style={{color: '#918d8d'}} />,
    enrichment: <PlayCircleOutlined style={{color: '#918d8d'}} />
};

const WorksheetManager = (props) => {
    const { userData, setHasError } = props;
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();

    const [worksheetId, setWorksheetId] = useState(searchParams.get('worksheet_id') || null);
    const [worksheetName, setWorksheetName] = useState("");
    const [lastIndexed, setLastIndexed] = useState(null);
    const [editingWorksheetName, setEditingWorksheetName] = useState(false);
    const [metadata, setMetadata] = useState([]);
    const [rows, setRows] = useState([]);
    const [columns, setColumns] = useState([]);
    const [loading, setLoading] = useState(false);
    const [saving, setSaving] = useState(false);
    const [cursorSelectedCell, setCursorSelectedCell] = useState(null);
    const [showSuccessAlert, setShowSuccessAlert] = useState(null);
    const [showFailedAlert, setShowFailedAlert] = useState(null);
    const [hasChanges, setHasChanges] = useState(false);
    const [newWorksheetName, setNewWorksheetName] = useState(null);
    const [products, setProducts] = useState([]);

    const [selectedCell, setSelectedCell] = useState({ rowIdx: null, colKey: null });
    const [selectedRows, setSelectedRows] = useState(() => new Set());
    const [selectedCells, setSelectedCells] = useState(new Set());
    const [isSelecting, setIsSelecting] = useState(false);
    const [startCellPos, setStartCellPos] = useState(null);

    const [newColumnModal, setNewColumnModal] = useState(false);
    const [columnTypeModal, setColumnTypeModal] = useState(false);
    const [newEnrichedColumnModal, setNewEnrichedColumnModal] = useState(false);
    const [newColumnName, setNewColumnName] = useState("");
    const [newColumnType, setNewColumnType] = useState("text");
    const [newColumnDictKey, setNewColumnDictKey] = useState(null);
    const [dictKeys, setDictKeys] = useState([]);
    const [columnsProcessing, setColumnsProcessing] = useState([]);
    const [editingEnrichment, setEditingEnrichment] = useState(false);
    const [importFlowOpen, setImportFlowOpen] = useState(false);
    const [exportFlowOpen, setExportFlowOpen] = useState(false);

    const [filters, setFilters] = useState({});
    const [sortConfig, setSortConfig] = useState({ columnKey: null, direction: null });

    // We'll hold the user's input for a filter in the modal before saving it
    const [filterInput, setFilterInput] = useState('');

    const abortControllerRef = useRef(null);
    const apiRef = useRef({});

    const DEFAULT_ROW_COUNT = 20;
    const DEFAULT_COL_COUNT = 10;

    // We'll keep our last "copied" data in a ref.
    const clipboardDataRef = useRef([]); // will hold a 2D array of strings

    const folderParam = searchParams.get('folder') || '';

    ///////////////////////////////////////////////////////////////////////////
    // Column-level actions: rename & remove, used by the header dropdown
    ///////////////////////////////////////////////////////////////////////////
    const renameColumn = useCallback((colKey, newName, newColumType = null, newDictKey = null) => {
        const newCols = columns.map((c) => {
            if (c.key === colKey) {
                return {
                    ...c,
                    name: newName,
                    column_type: newColumType || c.column_type,
                    // Store dictKey if provided
                    dictKey: newDictKey ?? c.dictKey
                };
            }
            return c;
        });
        const toReturn = normalizeColumns(newCols);
        setColumns(toReturn);
        setHasChanges(true);

        return toReturn;
    }, [columns]);

    const removeColumnByKey = (colKey) => {
        if (!colKey || colKey === 'rowIndex' || colKey === 'addColumn') return;

        // Also remove any filter for that column
        setFilters(prev => {
            const copy = { ...prev };
            delete copy[colKey];
            return copy;
        });

        // If that column was used for sorting, remove it
        setSortConfig(prev => {
            if (prev.columnKey === colKey) {
                return { columnKey: null, direction: null };
            }
            return prev;
        });

        const newCols = columns.filter(c => c.key !== colKey);
        const newRows = rows.map(r => {
            const { [colKey]: _, ...rest } = r;
            return rest;
        });

        // remove any bottom rows that are now fully null or empty after column removal
        const newRowsFiltered = newRows.filter(r => Object.values(r).some(v => v !== '' && v !== null && v !== undefined));

        const toReturn = normalizeColumns(newCols);
        setColumns(toReturn);
        setRows(newRowsFiltered);
        saveWorksheet({ newCols, newRows: newRowsFiltered });
        setHasChanges(true);
    };

    useEffect(() => {
        
        // this sets the loading state for the worksheet column headers
        if (columnsProcessing.length > 0) {
            setColumns(normalizeColumns(columns));
        }
    }, [columnsProcessing]);

    ///////////////////////////////////////////////////////////////////////////
    // Build the left-most rowIndex column
    ///////////////////////////////////////////////////////////////////////////
    const buildRowIndexColumn = useCallback(() => {
        return {
            key: 'rowIndex',
            name: '#',
            width: 70,
            editable: false,
            frozen: true,
            resizable: false,
            sortable: false,
            renderCell: ({ row }) => <span>{volumeFormatter.format(row.id + 1)}</span>,
        };
    }, []);
    
    const handleImportRows = async (
        importedWorksheets,
        wipeExisting,
        localDataOverride,
        options = {}
      ) => {
        const { ephemeral = false } = options;
        
        // 0) If localDataOverride is present, skip backend calls & override local data
        if (localDataOverride && localDataOverride.rows) {
          try {
            const newCols = normalizeColumns(localDataOverride.cols || []);
            setRows(localDataOverride.rows || []);
            setColumns(newCols);
            setHasChanges(true);
          } catch (err) {
            console.error("handleImportRows local override error:", err);
            throw err;
          } finally {
            setImportFlowOpen(false);
          }

          await saveWorksheet({newCols: localDataOverride.cols, newRows: localDataOverride.rows});
          return; // done
        }

        // 1) The original logic if no local override:
        try {
          const requestBody = {
            action: "batch_import",
            ephemeral,
            wipeExisting,
            importedWorksheets,
            localWorksheetId: worksheetId,
            folder: folderParam,
            localColumns: columns,
            existingRows: rows
          };
      
          const result = await fetchWorksheetData(userData.accessToken, requestBody);
          if (result.error) {
            throw new Error(result.error);
          }
      
          const { data, metadata } = result;
          setRows(data.rows || []);
          setColumns(normalizeColumns(data.cols || []));
          const newProcessingColKeys = metadata.processing_col_keys || [];
        
          setColumnsProcessing(prev => [...prev.filter((c) => !newProcessingColKeys.includes(c)), ...newProcessingColKeys]);
          setHasChanges(true);
      
          (metadata.processing_tasks || []).forEach((taskId) => {
            pollTaskUntilDone(taskId);
          });
      
          const newConfig = {
            ...(metadata.import_config || {}),
            importedWorksheets
          };
      
          if (!ephemeral && newConfig) {
            const updatedMeta = { ...metadata, import_config: newConfig };
            setMetadata(updatedMeta);
          }
        } catch (err) {
          console.error("handleImportRows error:", err);
          throw err;
        } finally {
          setImportFlowOpen(false);
        }
      };

    ///////////////////////////////////////////////////////////////////////////
    // The final “+ Add column” is always appended at the end
    ///////////////////////////////////////////////////////////////////////////
    const buildAddColumnColumn = useCallback(() => {
        return {
            key: 'addColumn',
            name: '+ Add column',
            editable: false,
            resizable: false,
            draggable: false,
            sortable: false,
            renderHeaderCell: ({ column }) => {
                return (
                    <div style={{ cursor: 'pointer' }}>
                        {column.name}
                        <CaretDownOutlined style={{ fontSize: 12, marginLeft: 6 }} />
                    </div>
                );
            },
            // This column’s cells are always empty
            renderCell: () => ''
        };
    }, []);

    const pollTaskUntilDone = (task_id) => {
        setShowSuccessAlert('Enrichment processing...');
        const startTime = Date.now();

        var counter = 0;

        const doPoll = () => {
            let waitTime;
            // the longer it's been, the less frequently we poll
            // exponential backoff
            if (counter > 6) {
                const elapsed = Date.now() - startTime;
                waitTime = Math.min(30000, Math.max(1000, Math.pow(1.3, elapsed / 1000) * 1000));
            } else {
                // first 6 times, poll every 500ms
                waitTime = 500;
            }

            fetchWorksheetData(userData.accessToken, {
                action: 'poll_enrichment',
                task_id: task_id
            }).then((result) => {
                if (result.error) {
                    setShowFailedAlert(result.error);
                    if (!result.is_reindex) {
                        setColumnsProcessing(prev => prev.filter((c) => c !== result['destination_col']));
                        setColumns(prev => normalizeColumns(prev.map((c) => {
                            if (c.key === result['destination_col']) {
                                return { ...c, error: result.error };
                            }
                            return c;
                        })));
                    } else {
                        setColumns(prev => normalizeColumns(prev.map((c) => {
                            return { ...c, error: result.error };
                        })));
                        setColumnsProcessing(prev => []);
                    }

                    return;
                } else if (result.status === 'missing') {
                    // old, deleted task
                    return;
                } else if (result.status === 'complete') {  
                    if (!result.is_reindex) {
                        setColumnsProcessing(prev => prev.filter((c) => c !== result['destination_col']));
                    } else {
                        setColumnsProcessing(prev => []);
                    }

                    if (!result.is_reindex) {
                        setColumns(prev => normalizeColumns(prev.map((c) => {
                            if (c.key === result['destination_col']) {
                                const col = result.worksheet_data.data.cols.find((col) => col.key === result['destination_col']);
                                return { ...col, error: null };
                            }
                            return c;
                        })));
                    } else {
                        setColumns(normalizeColumns(columns));
                    }

                    if (!result.is_reindex) {
                        setRows(result['worksheet_data']['data'].rows);
                    }
                    setHasChanges(false);

                    if (result.is_reindex)
                        setLastIndexed(result.last_indexed_at);

                    setShowSuccessAlert('Enrichment complete');

                    return;
                } else if (result.status === 'incomplete') {
                    if (!result.is_reindex) {
                        setRows(prev => prev.map((r, idx) => {
                            return {...r, [result['destination_col']]: result['partial_column_data'][idx]};
                        }));
                    }
                }
                setTimeout(doPoll, waitTime);
            });
        }
        
        doPoll();
    }

    ///////////////////////////////////////////////////////////////////////////
    // Build the actual columns from server data
    ///////////////////////////////////////////////////////////////////////////
    const normalizeColumns = useCallback(
        (cols, newRows = null, newFilters = null, newSortConfig = null) => {
            const allCols = cols.map((c) => {
                if (c.key === 'rowIndex') {
                    return buildRowIndexColumn();
                } else if (c.key === 'addColumn') {
                    return buildAddColumnColumn();
                }

                const useFilters = newFilters || filters;
                const useSortConfig = newSortConfig || sortConfig;

                return {
                    ...c,
                    // not editable if column has dict values
                    editable: !(columnsProcessing.includes(c.key) || c.key === 'addColumn' || c.key === 'rowIndex' || c.data_type=== 'object'),
                    resizable: c.resizable !== false,
                    draggable: c.draggable !== false,
                    column_type: c.column_type,
                    minWidth: 200,
                    renderEditCell: (editProps) => (
                        <CustomEditor
                            {...editProps}
                            rows={newRows || rows}
                            columns={cols || columns}

                        />
                    ),
                    renderCell: ({ row, column }) => {
                        const val = row[column.key];
                        let displayValue;
                    
                        if (
                          column.column_type === 'enrichment' &&
                          val &&
                          typeof val === 'object'
                        ) {
                            const subKey = column.dictKey || 'Name';
                            displayValue = val[subKey] ?? '';
                        } else {
                            displayValue = val ?? '';
                        }
                    
                        // If overridden, add a small dot indicator to the display
                        if (
                          (column.column_type === 'enrichment' || column.data_type === 'object')
                          && row._overrides?.[column.key]
                        ) {
                          return (
                            <span style={{ position: 'relative' }}>
                              {displayValue}
                              <span style={{ color: colorPrimary, marginLeft: 6, fontSize: 18}}>•</span>
                            </span>
                          );
                        }
                    
                        return displayValue;
                    },
                    sortable: c.sortable !== false,
                    renderHeaderCell: ({ column }) => {
                        // Column-type icon
                        const columnTypeIcon = colTypeToIcon[column.column_type];

                        // Show filter icon if a filter is active
                        const isFiltered = !!useFilters[column.key];
                        // Show sort icon if this column is sorted
                        const isSortedHere = useSortConfig.columnKey === column.key;
                        let sortIcon = null;
                        if (isSortedHere) {
                            sortIcon = useSortConfig.direction === 'asc'
                                ? <ArrowDownOutlined style={{ marginLeft: 8, fontSize: 14, color: colorPrimary }} />
                                : <ArrowUpOutlined style={{ marginLeft: 8, fontSize: 14, color: colorPrimary }} />;
                        }

                        return (
                            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    {columnTypeIcon && (
                                        <span style={{ marginRight: 8 }}>
                                            {columnTypeIcon}
                                        </span>
                                    )}
                                    {column.name}
                                    {isFiltered && (
                                        <FilterOutlined style={{ marginLeft: 8, fontSize: 14, color: colorPrimary}} />
                                    )}
                                    {sortIcon}
                                </div>
                                {columnsProcessing.includes(column.key) && (
                                    <LoadingOutlined style={{ fontSize: 16, color: colorPrimary }} />
                                )}
                                { column.error && (
                                    <Tooltip title={column.error}>
                                        <WarningTwoTone twoToneColor="#ff0000" style={{ fontSize: 16 }} />
                                    </Tooltip>
                                )}
                            </div>
                        )
                    },
                };
            });

            let finalCols = allCols;

            // add the final "Add column" column if not there
            if (allCols.length === 0 || allCols[allCols.length - 1].key !== 'addColumn') {
                finalCols = [...allCols, buildAddColumnColumn()];
            }

            // add the rowIndex column if not there
            if (allCols.length === 0 || allCols[0].key !== 'rowIndex') {
                finalCols = [buildRowIndexColumn(), ...finalCols];
            }

            return finalCols;
        },
        [rows, columns, buildRowIndexColumn, buildAddColumnColumn, columnsProcessing, filters, sortConfig]
    );

    ///////////////////////////////////////////////////////////////////////////
    // Compute rows to display (apply filters + sort)
    ///////////////////////////////////////////////////////////////////////////
    const displayedRows = useMemo(() => {
        let filtered = [...rows];

        // apply filters
        Object.entries(filters).forEach(([colKey, val]) => {
            filtered = filtered.filter(r => String(r[colKey] ?? '').toLowerCase().includes(val.toLowerCase()));
        });

        // apply sort (only one column)
        if (sortConfig.columnKey) {
            const { columnKey, direction } = sortConfig;
            filtered.sort((a, b) => {
                const aVal = a[columnKey] ?? '';
                const bVal = b[columnKey] ?? '';
                if (aVal < bVal) return direction === 'asc' ? -1 : 1;
                if (aVal > bVal) return direction === 'asc' ? 1 : -1;
                return 0;
            });
        }

        return filtered;
    }, [rows, filters, sortConfig]);

    ///////////////////////////////////////////////////////////////////////////
    // Load the worksheet from server
    ///////////////////////////////////////////////////////////////////////////
    const loadWorksheet = useCallback(
        async (id) => {
            setLoading(true);
            setShowFailedAlert(null);
            setShowSuccessAlert(null);
            setHasChanges(false);

            if (abortControllerRef.current) {
                abortControllerRef.current.abort();
            }
            abortControllerRef.current = new AbortController();

            try {
                const body = {
                    worksheet_id: id ? id : undefined,
                    folder: folderParam
                };
                const data = await fetchWorksheetData(userData.accessToken, body, {
                    signal: abortControllerRef.current.signal
                });

                if (data.error) {
                    setShowFailedAlert(data.error);
                    setLoading(false);
                    return;
                }

                let worksheetData = data.data;
                let activeMeta = data.metadata;

                setMetadata(activeMeta || []);

                const finalCols = normalizeColumns(worksheetData.cols || []);
                const updatedRows = worksheetData.rows || [];
                setWorksheetName(activeMeta.name);
                setNewWorksheetName(activeMeta.name);
                setLastIndexed(activeMeta.last_indexed_at);

                let allCols = normalizeColumns(
                    [...finalCols.filter((c) => !c.frozen)],
                    updatedRows
                );

                setColumnsProcessing(activeMeta ? activeMeta.processing_col_keys : []);

                (activeMeta.processing_tasks || []).forEach((taskId) => {
                    // start polling for incomplete tasks
                    pollTaskUntilDone(taskId);
                });

                setRows(updatedRows);
                setColumns(allCols);
            } catch (e) {
                if (e.name !== 'AbortError') {
                    setHasError(e);
                    console.error(e);
                }
            } finally {
                setLoading(false);
            }
        },
        [
            userData.accessToken,
            setHasError,
            navigate,
            normalizeColumns
        ]
    );

    useEffect(() => {
        if (showSuccessAlert) {
            const timer = setTimeout(() => {
                setShowSuccessAlert(null);
            }, 5000);
            return () => clearTimeout(timer);
        }
        if (showFailedAlert) {
            const timer = setTimeout(() => {
                setShowFailedAlert(null);
            }, 60000);
            return () => clearTimeout(timer);
        }
    }, [showSuccessAlert, showFailedAlert]);

    useEffect(() => {
        loadWorksheet(worksheetId);
        
        if (products.length === 0) {
            const fetch = async () => {
                const result = await fetchThreadsData(
                    userData.accessToken,
                    {'info_type': 'undismissed_count'}, 
                    { signal: abortControllerRef.current.signal }
                )

                return result;
            }

            fetch().then((result) => {
                setProducts(result['products']);
            });
        }

    }, []);

    const handleSelectedCellChange = useCallback((selectedCells) => {
        if (!selectedCell || !selectedCells.row) return;
        const rowIdx = selectedCells.row.id;
        const colKey = selectedCells.column.key;
        setCursorSelectedCell({ rowIdx, colKey });
    }, [selectedCell]);

    ///////////////////////////////////////////////////////////////////////////
    // Save current worksheet to server
    ///////////////////////////////////////////////////////////////////////////
    const saveWorksheet = async ({ newConfig = null, newNameForSave = null, newCols = null, newRows = null } = {}) => {
        setSaving(true);
        setShowFailedAlert(null);
        setShowSuccessAlert(null);
        const rowsNow = newRows ? newRows : [...rows];
        const colsNow = newCols ? newCols : [...columns];

        try {
            const body = {
                action: 'save',
                name: worksheetName,
                newWorksheetName: newNameForSave,
                worksheet_id: worksheetId ? worksheetId : null,
                folder: folderParam,
                importConfig: newConfig ? newConfig : metadata.import_config,
                data: { rows: rowsNow, cols: colsNow }
            };
            const result = await fetchWorksheetData(userData.accessToken, body);

            if (result.error) {
                setShowFailedAlert(result.error);
            } else {
                setShowSuccessAlert('Worksheet saved');
                setHasChanges(false);
            }
        } catch (e) {
            setShowFailedAlert(e.message);
        } finally {
            setSaving(false);
        }
    };

    ///////////////////////////////////////////////////////////////////////////
    // Row and Column “add/remove”
    ///////////////////////////////////////////////////////////////////////////
    const addRow = useCallback(() => {
        const newRow = { id: rows.length };
        columns.forEach((c) => {
            if (c.key !== 'select-row') {
                newRow[c.key] = "";
            }
        });
        const newRows = [...rows, newRow];
        setRows(newRows);
        setHasChanges(true);

        saveWorksheet({ newRows });
        return newRows;
    }, [rows, columns]);

    const removeRow = useCallback(() => {
        if (
            selectedCell.rowIdx == null ||
            selectedCell.rowIdx < 0 ||
            selectedCell.rowIdx >= rows.length
        ) {
            return;
        }
        const newRows = rows.filter((_, idx) => idx !== selectedCell.rowIdx);
        newRows.forEach((r, i) => (r.id = i));
        setRows(newRows);
        setHasChanges(true);
        saveWorksheet({ newRows });
    }, [rows, selectedCell]);

    const addColumn = useCallback((colName, config={}, doNotSetCols=false) => {
        const newKey = uuid4();
        const newCol = {
            key: newKey,
            name: colName,
            editable: true,
            resizable: true,
            draggable: true,
            sortable: true,
            ...config
        };
        const existingColsNoAdd = columns.slice(1, columns.length - 1);
        const normalizedNewCols = normalizeColumns([...existingColsNoAdd, newCol]);
        const newRows = rows.map((r) => ({ ...r, [newKey]: "" }));
        if (!doNotSetCols) {
            setColumns(normalizedNewCols);
        }
        setRows(newRows);
        setHasChanges(true);

        return normalizedNewCols;
    }, [columns, rows, normalizeColumns]);

    const removeColumn = () => {
        if (!selectedCell.colKey || selectedCell.colKey === 'select-row') return;
        const removedKey = selectedCell.colKey;
        if (removedKey === 'rowIndex' || removedKey === 'addColumn') return;
        const newCols = columns.filter((c) => c.key !== removedKey);
        const newRows = rows.map((r) => {
            const { [removedKey]: _, ...rest } = r;
            return rest;
        });
        setColumns(newCols);
        setRows(newRows);
        saveWorksheet({ newCols, newRows });
        setHasChanges(true);
    }

    const deleteWorksheet = async () => {
        if (!worksheetId) {
            setShowFailedAlert("No worksheet selected to delete");
            return;
        }
        const body = {
            action: 'delete',
            folder: folderParam,
            worksheet_id: worksheetId
        };
        try {
            setShowSuccessAlert('Deleting...');
            const result = await fetchWorksheetData(userData.accessToken, body);
            if (result.error) {
                setShowFailedAlert(result.error);
            } else {
                setWorksheetId(null);
                loadWorksheet(null);
                navigate('/orchestrate/home');
            }
        } catch (e) {
            setShowFailedAlert(e.message);
        }
    };

    // called by the custom editor
    const onRowsChange = (updatedFilteredRows) => {
        const newRows = rows.map(row => {
          const matchingRow = updatedFilteredRows.find(fr => fr.id === row.id);
          return matchingRow || row;
        });
      
        setRows(newRows);
      
        saveWorksheet({ newRows });
        setHasChanges(true);
    };

    function onColumnsReorder(sourceKey, targetKey) {
        const sourceIndex = columns.findIndex((c) => c.key === sourceKey);
        const targetIndex = columns.findIndex((c) => c.key === targetKey);
        if (sourceIndex === -1 || targetIndex === -1) return;

        const newCols = [...columns];
        const [movedColumn] = newCols.splice(sourceIndex, 1);
        newCols.splice(targetIndex, 0, movedColumn);

        setColumns(newCols);
        setHasChanges(true);
        saveWorksheet({ newCols });
    }

    function rowKeyGetter(row) {
        return row ? row.id : -1;
    }

    function commitWorksheetName() {
        setEditingWorksheetName(false);
        setWorksheetName(newWorksheetName);
        saveWorksheet({ newNameForSave: newWorksheetName });
    }

    ///////////////////////////////////////////////////////////////////////////
    // Some selection helpers
    ///////////////////////////////////////////////////////////////////////////
    function getSelectionBounds(selCells) {
        if (selCells.size === 0) return null;
        let minRow = Infinity,
            maxRow = -Infinity;
        let minCol = Infinity,
            maxCol = -Infinity;

        for (const cellStr of selCells) {
            const { r, c } = JSON.parse(cellStr);
            const colIndex = columns.findIndex((col) => col.key === c);
            if (r < minRow) minRow = r;
            if (r > maxRow) maxRow = r;
            if (colIndex < minCol) minCol = colIndex;
            if (colIndex > maxCol) maxCol = colIndex;
        }

        return { minRow, maxRow, minCol, maxCol };
    }

    function buildTsvString(data2D) {
        return data2D
            .map((row) =>
                row
                    .map((cell) => {
                        const str = (cell ?? '').toString();
                        return str.replace(/\t/g, '    ');
                    })
                    .join('\t')
            )
            .join('\r\n');
    }

    function parseTsv(text) {
        const lines = text.split(/\r?\n/).filter((line) => line !== '');
        return lines.map((line) => line.split('\t'));
    }

    function parseClipboardData(text) {
        if (text.includes('\t')) {
            // parse as TSV
            return parseTsv(text);
        } else {
            const lines = text.split(/\r?\n/).filter((line) => line !== '');
            return lines.map((line) => [line]);
        }
    }

    function onCellClick({ column, row }) {
        const rowIdx = row.id;
        setSelectedCell({ rowIdx, colKey: column.key });
    }

    const importWorksheet = () => {
        setImportFlowOpen(true);
    }

    apiRef.current.selectCellRange = (start, end, keepOtherSelected = false) => {
        const startRow = Math.min(start.rowIdx, end.rowIdx);
        const endRow = Math.max(start.rowIdx, end.rowIdx);
        const startColIndex = columns.findIndex((c) => c.key === start.colKey);
        const endColIndex = columns.findIndex((c) => c.key === end.colKey);

        const minCol = Math.min(startColIndex, endColIndex);
        const maxCol = Math.max(startColIndex, endColIndex);

        const newSelected = new Set(keepOtherSelected ? selectedCells : []);
        for (let r = startRow; r <= endRow; r++) {
            for (let c = minCol; c <= maxCol; c++) {
                const cellKey = JSON.stringify({ r, c: columns[c].key });
                newSelected.add(cellKey);
            }
        }
        setSelectedCells(newSelected);
    };

    const buildDataSource = async () => {
        // action is a type of "enrichment" on backend
        const allColKeys = columns.filter(
            (c) => c.key !== 'rowIndex' && c.key !== 'addColumn'
        ).map((c) => c.key);

        setColumnsProcessing([...allColKeys]);

        const body = {
            action: 'enrichment',
            worksheet_id: worksheetId,
            folder: folderParam,
            type: 'data_source_conversion',
            sourceCols: allColKeys,
            data: { rows, cols: columns }
        };

        fetchWorksheetData(userData.accessToken, body).then((result) => {
            if (result.error) {
                setShowFailedAlert(result.error);
            } else {
                pollTaskUntilDone(result.task_id);
                setHasChanges(false);
            }
        });
    }
    
    const worksheetActionMenu = (
        <Menu
          onClick={(info) => {
            if (info.key === 'add_row') addRow();
            else if (info.key === 'remove_row') removeRow();
            else if (info.key === 'remove_col') removeColumn();
            else if (info.key === 'data_source') buildDataSource();
            else if (info.key === 'delete') deleteWorksheet();
            else if (info.key === 'import') importWorksheet();
            else if (info.key === 'export') setExportFlowOpen(true); // <-- handle export
          }}
        >
          <Menu.Item key="add_row">+ Add bottom row</Menu.Item>
          <Menu.Item key="remove_row" disabled={selectedCell.rowIdx == null}>
            Remove selected row
          </Menu.Item>
          <Menu.Item
            key="remove_col"
            disabled={
              !selectedCell.colKey ||
              selectedCell.colKey === 'select-row' ||
              selectedCell.colKey === 'rowIndex' ||
              selectedCell.colKey === 'addColumn'
            }
          >
            Remove selected column
          </Menu.Item>
          <Menu.Item key="import">
            <CloudUploadOutlined style={{ marginRight: 5 }}/>Import
          </Menu.Item>
          <Menu.Item key="export">
            <DownloadOutlined style={{ marginRight: 5 }} />Export
          </Menu.Item>
          <Menu.Item key="data_source">
            <FontAwesomeIcon style={{ marginRight: 5 }} icon={faWandMagicSparkles} /> Train AI on worksheet
          </Menu.Item>
          <Menu.Item key="delete" disabled={!worksheetId} danger>
            <DeleteOutlined style={{ marginRight: 5 }} /> Delete
          </Menu.Item>
        </Menu>
    );

    const gridRef = useRef(null);
    const shadowGridRef = useRef(null);

    const handleUpdateEnrichedColumn = async (colKey) => {
        setNewColumnModal(false);

        const body = columns.find((c) => c.key === colKey).enrichment_config;
        body['action'] = 'enrichment';

        const data = {
            rows,
            cols: columns
        }

        setColumnsProcessing([...columnsProcessing.filter((c) => c === colKey), colKey]);

        fetchWorksheetData(userData.accessToken, {
            ...body,
            data: data
        }).then((result) => {
            if (result.error) {
                setShowFailedAlert(result.error);
            } else {
                pollTaskUntilDone(result.task_id);
                setHasChanges(false);
            }
        });
    }

    const handleNewEnrichedColumn = async (type, colKeys, config, editExisting) => {
        setNewEnrichedColumnModal(false);

        const configCopy = { ...config };
        
        let allNewColumns;
        if (editExisting) {
            // replace the existing column with the new one
            allNewColumns = [...columns.map((c) => {
                if (c.key === selectedCell.colKey) {
                    return {
                        ...c,
                        name: configCopy['column_name'],
                        column_type: configCopy['column_type'],
                        inputCols: configCopy['inputCols'],
                        data_type: configCopy['data_type']
                    }
            }
                return c;
            })];
        } else {
            allNewColumns = addColumn(
                configCopy['column_name'], 
                { column_type: configCopy['column_type'], inputCols: configCopy['inputCols'], data_type: configCopy['data_type'] },
                true
            );
        }

        let newCol;
        if (editExisting) {
            newCol = allNewColumns.find((c) => c.key === selectedCell.colKey);
        } else {
            newCol = allNewColumns[allNewColumns.length - 2];
        }

        setEditingEnrichment(false);

        const body = {
            ...configCopy,
            action: 'enrichment',
            worksheet_id: worksheetId,
            folder: folderParam,
            type: type,
            sourceCols: colKeys,
            destinationCol: newCol.key,
        };
        const newColWithConfig = { ...newCol, enrichment_config: body };

        let allNewColsWithConfig;
        if (editExisting) {
            allNewColsWithConfig = allNewColumns.map((c) => {
                if (c.key === newCol.key) {
                    return newColWithConfig;
                }
                return c;
            });
        } else {
            allNewColsWithConfig = [
                ...allNewColumns.slice(0, allNewColumns.length - 2),
                newColWithConfig,
                allNewColumns[allNewColumns.length - 1]
            ];
        }

        setColumns(allNewColsWithConfig);
        setColumnsProcessing(prev => [...prev.filter((c) => !colKeys.includes(c)), newCol.key]);

        const data = {
            rows,
            cols: allNewColsWithConfig
        }

        // send to backend
        fetchWorksheetData(userData.accessToken, {
            ...body,
            data
        }).then((result) => {
            if (result.error) {
                setShowFailedAlert(result.error);
            } else {
                pollTaskUntilDone(result.task_id);
                setHasChanges(false);
            }
        });
    }

    const getCellFromMouseEvent = (e) => {
        const shadow = shadowGridRef.current?.getShadowRoot();
        let realTarget = e.target;
        if (shadow && !shadow.contains(e.target)) {
            realTarget = shadow.elementFromPoint(e.clientX, e.clientY);
        }
        const cellEl = realTarget?.closest('.rdg-cell');
        if (!cellEl) return null;

        const colIdx = parseInt(cellEl.getAttribute('aria-colindex')) - 1;
        const colKey = columns[colIdx]?.key;

        let rowIdx;
        const rowEl = realTarget.closest('.rdg-row');
        if (!rowEl) {
            // header
            rowIdx = -1;
        } else {
            const offset = 2;
            rowIdx = parseInt(rowEl.getAttribute('aria-rowindex'), 10) - offset;
        }
        return { rowIdx, colKey };
    };

    // We use mouseUp to detect the column click
    const handleMouseUp = (e) => {
        const cell = getCellFromMouseEvent(e);
        if (!cell) return;

        const { rowIdx, colKey } = cell;
        setIsSelecting(true);

        if (rowIdx < 0) {
            // user clicked a header
            const start = { rowIdx: 0, colKey };
            const end = { rowIdx: rows.length - 1, colKey };
            apiRef.current.selectCellRange(start, end, false);
            setSelectedCell({ rowIdx: 0, colKey });

            if (colKey === 'addColumn') {
                setColumnTypeModal(true);
                setNewColumnName('');
                setNewColumnType('text');
            } else {
                // If there's already a filter for this column, load it into filterInput
                setFilterInput(filters[colKey] || '');
                setNewColumnName(columns.find((c) => c.key === colKey)?.name);
                setNewColumnType(columns.find((c) => c.key === colKey)?.column_type);

                const chosenCol = columns.find(c => c.key === colKey);
                if (chosenCol && chosenCol.column_type === 'enrichment') {
                    const keySet = new Set();
                    rows.slice(0, 100).forEach(r => {
                        const val = r[chosenCol.key];
                        if (val && typeof val === 'object') {
                            Object.keys(val).forEach(k => keySet.add(k));
                        }
                    });
                    const arr = Array.from(keySet);
                    // Store them in a state so we can show them in the modal
                    setDictKeys(arr);
                    // Default to existing dictKey or "Name"
                    setNewColumnDictKey(chosenCol.dictKey || 'Name');
                } else {
                    setDictKeys([]);
                    setNewColumnDictKey(null);
                }

                setNewColumnModal(true);
            }
            return;
        }

        if (colKey === 'rowIndex') {
            // user clicked row index
            const firstDataCol = columns[1].key;
            const lastDataCol = columns[columns.length - 1].key;
            const start = { rowIdx, colKey: firstDataCol };
            const end = { rowIdx, colKey: lastDataCol };
            apiRef.current.selectCellRange(start, end, false);
            return;
        }

        setStartCellPos(cell);
        apiRef.current.selectCellRange(cell, cell, false);
    };

    const handleKeyDown = async (e) => {
        const ctrlOrCmd = e.ctrlKey || e.metaKey;

        // Copy
        if (ctrlOrCmd && e.key.toLowerCase() === 'c') {
            e.preventDefault();
            e.stopPropagation();
            await copySelectionToClipboard(false);
            return;
        }

        // Paste
        if (ctrlOrCmd && e.key.toLowerCase() === 'v') {
            e.preventDefault();
            e.stopPropagation();
            await pasteFromClipboard();
            return;
        }
    };

    async function copySelectionToClipboard(cut = false) {
        const bounds = getSelectionBounds(selectedCells);
        if (!bounds) return;
        const { minRow, maxRow, minCol, maxCol } = bounds;

        const data = [];
        for (let r = minRow; r <= maxRow; r++) {
            const rowArr = [];
            for (let c = minCol; c <= maxCol; c++) {
                const colKey = columns[c]?.key;
                rowArr.push(displayedRows[r]?.[colKey] ?? '');
            }
            data.push(rowArr);
        }

        clipboardDataRef.current = data;
        const tsvString = buildTsvString(data);

        if (window.isSecureContext && navigator.clipboard) {
            try {
                await navigator.clipboard.writeText(tsvString);
            } catch (err) {
                console.warn('Clipboard write failed; using local data only:', err);
            }
        }

        if (cut) {
            const updatedRows = [...rows];
            for (let r = minRow; r <= maxRow; r++) {
                for (let c = minCol; c <= maxCol; c++) {
                    const colKey = columns[c]?.key;
                    if (updatedRows[r] && colKey in updatedRows[r]) {
                        updatedRows[r][colKey] = '';
                    }
                }
            }
            setRows(updatedRows);
            setHasChanges(true);
        }
    }

    async function pasteFromClipboard() {
        let text = null;
        if (window.isSecureContext && navigator.clipboard) {
            try {
                text = await navigator.clipboard.readText();
            } catch (err) {
                console.warn('Clipboard read failed; using local data:', err);
            }
        }

        let data2D;
        if (text) {
            data2D = parseClipboardData(text);
        } else {
            data2D = clipboardDataRef.current;
            if (!data2D || data2D.length === 0) {
                return;
            }
        }

        const bounds = getSelectionBounds(selectedCells);
        let startRow = 0;
        let startCol = 1;
        if (cursorSelectedCell) {
            startRow = cursorSelectedCell.rowIdx;
            startCol = columns.findIndex((c) => c.key === cursorSelectedCell.colKey);
        } else if (bounds) {
            startRow = bounds.minRow;
            startCol = bounds.minCol;
        }

        const updatedRows = [...rows];
        for (let r = 0; r < data2D.length; r++) {
            const rowIndex = startRow + r;
            if (rowIndex >= updatedRows.length) {
                const newRow = { id: updatedRows.length };
                columns.forEach((col) => {
                    if (col.key !== 'rowIndex') {
                        newRow[col.key] = '';
                    }
                });
                updatedRows.push(newRow);
            }
            for (let c = 0; c < data2D[r].length; c++) {
                const colIndex = startCol + c;
                if (colIndex < columns.length) {
                    const colKey = columns[colIndex].key;
                    updatedRows[rowIndex][colKey] = data2D[r][c];
                }
            }
        }

        updatedRows.forEach((row, idx) => (row.id = idx));
        setRows(updatedRows);

        setColumns(normalizeColumns(columns, updatedRows));
        setShowSuccessAlert(`Pasted ${data2D.length} rows, ${data2D[0].length} columns`);
        setHasChanges(true);
    }

    return (
        <div style={{ height: 'calc(100vh - 58px)', display: 'flex', flexDirection: 'column', padding: 15 }}>
            {rows.length > 100000 && (
                <Alert
                    message={`Worksheet not saved - over 100,000 row limit`}
                    type="error"
                    showIcon
                    style={{
                        position: 'absolute',
                        top: 65,
                        left: '50%',
                        transform: 'translateX(-50%)',
                        zIndex: 1000,
                        width: '30%'
                    }}
                />
            )}
            {showSuccessAlert && (
                <Alert
                    message={showSuccessAlert}
                    type="success"
                    showIcon
                    style={{
                        position: 'absolute',
                        bottom: 10,
                        left: '50%',
                        transform: 'translateX(-50%)',
                        zIndex: 1000,
                        width: '25%'
                    }}
                />
            )}
            {showFailedAlert && (
                <Alert
                    message={showFailedAlert}
                    type="error"
                    showIcon
                    closable
                    style={{
                        position: 'absolute',
                        bottom: 10,
                        left: '50%',
                        transform: 'translateX(-50%)',
                        zIndex: 1000,
                        width: '25%'
                    }}
                />
            )}
            <div style={{ display: 'flex', alignItems: 'center', marginBottom: 10, gap: 10 }}>
                <div style={{ fontSize: '1.2em', fontWeight: 'bold', display: 'flex' }}>
                    <Button type="link" onClick={() => navigate('/orchestrate/home')}>
                        <ArrowLeftOutlined />
                        Back
                    </Button>
                    {loading ? (
                        <Skeleton.Input style={{ width: 200 }} active />
                    ) : editingWorksheetName ? (
                        <Input
                            value={newWorksheetName}
                            onChange={(e) => setNewWorksheetName(e.target.value)}
                            autoFocus
                            onBlur={commitWorksheetName}
                            onPressEnter={commitWorksheetName}
                            style={{ width: 200 }}
                        />
                    ) : (
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <div
                                style={{
                                    border: '1px solid #d9d9d9',
                                    borderRadius: 5,
                                    padding: 5,
                                    marginRight: 15
                                }}
                                onClick={() => setEditingWorksheetName(true)}
                            >
                                <span style={{ marginRight: 15 }}>{worksheetName}</span>
                                <EditOutlined style={{ fontSize: '12px', cursor: 'pointer' }} />
                            </div>
                            <Typography.Text
                               type='secondary'
                            >
                                {volumeFormatter.format(rows.length)} rows
                            </Typography.Text>
                        </div>
                    )}
                </div>

                <div style={{ flex: 1 }}></div>
                <Typography.Text
                    type='secondary'
                >AI last trained: {lastIndexed ? formatTimeAgo(lastIndexed) : 'never'}</Typography.Text>
                <Dropdown overlay={worksheetActionMenu} trigger={['click']}>
                    <Button>Actions <CaretDownOutlined /></Button>
                </Dropdown>
                <Button
                    type='primary'
                    onClick={() => setNewEnrichedColumnModal(true)}
                    icon={<FontAwesomeIcon icon={faWandMagicSparkles}/>}
                >
                    Add enrichment
                </Button>
            </div>

            <div
                ref={gridRef}
                tabIndex={0}
                onKeyDownCapture={handleKeyDown}
                onMouseUp={handleMouseUp}
                style={{ flex: 1, position: 'relative', height: 'calc(100vh - 10%)', overflow: 'auto' }}
            >
                {loading ? (
                    <div style={{ padding: 15 }}>
                        <Skeleton active paragraph={{ rows: 15 }} />
                    </div>
                ) : (
                    <DataGrid
                        ref={shadowGridRef}
                        rowKeyGetter={rowKeyGetter}
                        onSelectedCellChange={handleSelectedCellChange}
                        columns={columns}
                        rows={displayedRows}  // NOTE: We pass the filtered+sorted rows here
                        onRowsChange={onRowsChange}
                        onColumnsReorder={onColumnsReorder}
                        className="rdg-light"
                        onCellClick={onCellClick}
                        selectedRows={selectedRows}
                        onSelectedRowsChange={setSelectedRows}
                    />
                )}
            </div>

            <Modal
                title="Choose column type"
                open={columnTypeModal}
                onCancel={() => setColumnTypeModal(false)}
                footer={null}
            >
                <List
                    dataSource={[
                        { name: 'Enriched column', value: 'enriched' },
                        { name: 'Data column', value: 'data' },
                    ]}
                    renderItem={(item) => (
                        <List.Item
                            style={{ cursor: 'pointer' }}
                            onClick={() => {
                                if (item.value === 'enriched') {
                                    setNewEnrichedColumnModal(true);
                                } else {
                                    setNewColumnModal(true);
                                }
                                setColumnTypeModal(false);
                            }}
                        >
                            <Typography.Text>{item.name}</Typography.Text>
                        </List.Item>
                    )}
                />
            </Modal>

            <Modal
                title={
                    selectedCell.colKey !== 'addColumn'
                        ? <>{
                            (
                                colTypeToIcon[columns.find((c) => c.key === selectedCell.colKey)?.column_type || 'text']
                            )
                        }
                        <Input
                            style={{ marginLeft: 5, marginBottom: 15, maxWidth: '70%'}}
                            value={newColumnName}
                            onChange={(e) => setNewColumnName(e.target.value)}
                        />
                        </>
                        : 'Add column'
                }
                open={newColumnModal}
                footer={null}
                onCancel={() => setNewColumnModal(false)}
            >
                {selectedCell.colKey === 'addColumn' && (
                    <>
                        <Typography.Text>Column name</Typography.Text>
                        <Input
                            style={{ marginBottom: 15 }}
                            value={newColumnName}
                            onChange={(e) => setNewColumnName(e.target.value)}
                        />
                        <Select 
                            placeholder="Select type"
                            style={{ width: 400 }}
                            value={newColumnType}
                            onChange={(value) => setNewColumnType(value)}
                            options={[
                                { label: 'Text', value: 'text' },
                                { label: 'Number', value: 'number' },
                            ]}
                        />
                        <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: 40 }}>
                            <div></div>
                            <div>
                                <Button style={{marginRight: 15}} onClick={() => setNewColumnModal(false)}>
                                    Cancel
                                </Button>
                                <Button
                                    type="primary"
                                    onClick={() => {
                                        let newCols;
                                        if (selectedCell.colKey !== 'addColumn') {
                                            newCols = renameColumn(selectedCell.colKey, newColumnName, newColumnType);
                                        } else {
                                            newCols = addColumn(newColumnName, { column_type: newColumnType });
                                        }
                                        setNewColumnModal(false);
                                        saveWorksheet({ newCols });
                                    }}
                                >
                                    Add column
                                </Button>
                            </div>
                        </div>
                    </>
                )}
                {selectedCell.colKey !== 'addColumn' && (
                    <>
                            <Row style={{ marginTop: 20 }}>
                                <Col flex={4}>
                                    <Typography.Text style={{ display: 'block' }}>Sort</Typography.Text>
                                    <Button
                                        icon={
                                            sortConfig.columnKey === selectedCell.colKey
                                                ? (sortConfig.direction === 'asc'
                                                    ? <ArrowDownOutlined />
                                                    : sortConfig.direction === 'desc'
                                                        ? <CloseCircleOutlined />
                                                        : <ArrowUpOutlined />)
                                                : <ArrowUpOutlined />
                                        }
                                        onClick={() => {
                                            if (sortConfig.columnKey !== selectedCell.colKey) {
                                                setSortConfig({ columnKey: selectedCell.colKey, direction: 'asc' });
                                            } else {
                                                if (sortConfig.direction === 'asc') {
                                                    setSortConfig({ columnKey: selectedCell.colKey, direction: 'desc' });
                                                } else if (sortConfig.direction === 'desc') {
                                                    setSortConfig({ columnKey: null, direction: null });
                                                } else {
                                                    setSortConfig({ columnKey: selectedCell.colKey, direction: 'asc' });
                                                }
                                            }
                                        }}
                                    >
                                        {sortConfig.columnKey === selectedCell.colKey
                                            ? (sortConfig.direction === 'asc'
                                                ? 'Descending'
                                                : sortConfig.direction === 'desc'
                                                    ? 'Disable sort'
                                                    : 'Sort Asc')
                                            : 'Ascending'}
                                    </Button>
                                </Col>
                                <Col flex={20}>
                                    <Typography.Text style={{ display: 'block' }}>
                                        <FilterOutlined style={{ marginRight: 5 }} />
                                        Filter
                                    </Typography.Text>
                                    <Input
                                        value={filterInput}
                                        allowClear
                                        onChange={(e) => setFilterInput(e.target.value)}
                                    />
                                </Col>
                            </Row>

                            <Divider />
                        
                        {newColumnType !== 'enrichment' && (
                            <>
                                <Typography.Text>Change Type</Typography.Text>
                                <Select
                                    placeholder="Select type"
                                    style={{ width: 400 }}
                                    value={newColumnType}
                                    onChange={(value) => setNewColumnType(value)}
                                    options={[
                                        { label: 'Text', value: 'text' },
                                        { label: 'Number', value: 'number' },
                                    ]}
                                    // render icons for options and labels
                                    labelRender={(option) => {
                                        return (
                                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                                {option.value === 'text' && <FontSizeOutlined style={{color: '#918d8d', marginRight: 5}} />}
                                                {option.value === 'number' && <FieldBinaryOutlined style={{color: '#918d8d', marginRight: 5}} />}
                                                {option.label}
                                            </div>
                                        );
                                    }}
                                    optionRender={(option) => (
                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                            {option.value === 'text' && <FontSizeOutlined style={{color: '#918d8d', marginRight: 5}} />}
                                            {option.value === 'number' && <FieldBinaryOutlined style={{color: '#918d8d', marginRight: 5}} />}
                                            {option.label}
                                        </div>
                                    )}
                                />
                            </>
                        )}
                        {newColumnType === 'enrichment' && (
                            <>
                                {dictKeys.length > 0 && (
                                    <>
                                        <Typography.Text>Display column property</Typography.Text>
                                        <Select
                                            style={{ width: 400, marginBottom: 15 }}
                                            value={newColumnDictKey}
                                            onChange={(v) => setNewColumnDictKey(v)}
                                            options={dictKeys.map(k => ({ label: k, value: k }))}
                                        />
                                    </>
                                )}
                                <Button
                                    icon={<EditOutlined />}
                                    onClick={() => {
                                        setEditingEnrichment(true);
                                        setNewEnrichedColumnModal(true);
                                        setNewColumnModal(false);
                                    }}
                                    style={{ marginBottom: 15, marginTop: 15 }}
                                >
                                    Edit enrichment config
                                </Button>
                                <br/>
                                <Button 
                                    type="primary" 
                                    icon={<PlayCircleOutlined />}
                                    onClick={() => handleUpdateEnrichedColumn(selectedCell.colKey)}
                                >
                                    Run again
                                </Button>
                            </>
                        )}
                        <Divider />
                        <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: 20 }}>
                            <Button
                                danger
                                onClick={() => {
                                    removeColumnByKey(selectedCell.colKey);
                                    setNewColumnModal(false);
                                }}
                            >
                                <DeleteOutlined />
                                Remove column
                            </Button>
                            <div>
                                <Button style={{ marginRight: 15 }} onClick={() => setNewColumnModal(false)}>
                                    Cancel
                                </Button>
                                <Button
                                    type="primary"
                                    onClick={() => {
                                        let newCols;
                                        if (selectedCell.colKey !== 'addColumn') {
                                            newCols = renameColumn(
                                                selectedCell.colKey,
                                                newColumnName,
                                                newColumnType,
                                                newColumnDictKey
                                            );
                                        } else {
                                            newCols = addColumn(newColumnName, {column_type: newColumnType});
                                        }

                                        // If filterInput is blank, remove the filter; otherwise set it
                                        let newFilters;
                                        if (filterInput.trim()) {
                                            newFilters = { ...filters, [selectedCell.colKey]: filterInput.trim() };
                                        } else {
                                            newFilters = { ...filters };
                                            delete newFilters[selectedCell.colKey];
                                        }
                                        setFilters(prev => newFilters);

                                        setColumns(prev => normalizeColumns(newCols, null, newFilters));

                                        setNewColumnModal(false);

                                    }}
                                >
                                    Ok
                                </Button>
                            </div>
                        </div>
                    </>
                )}
            </Modal>

            <EnrichmentFlow
                userData={userData}
                products={products}
                open={newEnrichedColumnModal}
                editingEnrichmentConfigBody={editingEnrichment ? columns.find((c) => c.key === selectedCell.colKey)?.enrichment_config : null}
                currentWorksheetId={worksheetId}
                onClose={() => {
                    setNewEnrichedColumnModal(false);
                    setEditingEnrichment(false);
                }}
                onOk={handleNewEnrichedColumn}
                columns={columns}
                rows={rows}
            />
            <ImportFlow
                open={importFlowOpen}
                onClose={() => setImportFlowOpen(false)}
                userData={userData}
                currentWorksheetId={worksheetId}
                columns={columns}
                importConfig={metadata.import_config || null}
                onImportRows={handleImportRows}
                products={products}
            />
            <ExportFlow
                open={exportFlowOpen}
                onClose={() => setExportFlowOpen(false)}
                columns={columns}
                rows={rows}
            />
        </div>
    );
};

export default WorksheetManager;