import React, { useState, useEffect, useRef } from "react";
import { useSearchParams } from 'react-router-dom';
import { Layout, Typography, Tooltip, Button, Row, Alert, Space, Tabs, Card, List, Avatar} from 'antd';
import {LoadingOutlined, MailOutlined, DollarCircleOutlined} from '@ant-design/icons'
import Loading from "../Loading";
import {Link} from 'react-router-dom';
import { fetchOpenOrderData } from "../../endpoints/fetchOpenOrderData";
import { fetchThreadsData } from "../../endpoints/fetchThreadsData";
import { currencyFormatter, generateOrderSummary, getAttachmentIcon } from "../utils";
import {OrderEntryForm} from "./OrderForm";
import { useNavigate } from "react-router-dom";
import { EmailContent } from "./EmailContent";
import {EmailEditor} from "./SendEmail";
import { unitDisplayLabels } from "../Settings/OrderSettings";

import dayjs from "dayjs";
import TabPane from "antd/es/tabs/TabPane";


const { Sider, Content } = Layout;
const { Title, Text } = Typography;

const Order = (props) => {
    const { userData, setHasError } = props;
    const [searchParams] = useSearchParams();
    const [dataSource, setDataSource] = useState(null);
    const [imported, setImported] = useState(null);
    const [isFormEdited, setIsFormEdited] = useState(false);
    const [isLineItemsEdited, setIsLineItemsEdited] = useState(false);
    const [isFormValid, setIsFormValid] = useState(false);
    const [isFormValidMessage, setIsFormValidMessage] = useState(null);
    const [formState, setFormState] = useState({});
    const [orderId, setOrderId] = useState(null);
    const [saving, setSaving] = useState(null);
    const [showSuccessAlert, setShowSuccessAlert] = useState(null);
    const [showFailedAlert, setShowFailedAlert] = useState(null);
    const [showWarningAlert, setShowWarningAlert] = useState(null);
    const [emailModal, setEmailModal] = useState(false);
    const [activeTabKey, setActiveTabKey] = useState('1');
    const [invoiceModal, setInvoiceModal] = useState(false);

    const nav = useNavigate();

    const abortControllerRef = useRef(null);

    useEffect(() => {
        if (showSuccessAlert) {
            const timer = setTimeout(() => {
                setShowSuccessAlert(null);
            }, 5000);
            return () => clearTimeout(timer);
        }
        if (showFailedAlert) {
            const timer = setTimeout(() => {
                setShowFailedAlert(null);
            }, 60000);
            return () => clearTimeout(timer);
        }
        if (showWarningAlert) {
            const timer = setTimeout(() => {
                setShowWarningAlert(null);
            }, 60000);
            return () => clearTimeout(timer);
        }
    }, [showSuccessAlert, showFailedAlert, showWarningAlert]);

    useEffect(() => {
        if (!dataSource) return;

        setFormState({
            DOCUMENT_ID: dataSource.order.properties.external_id,
            PURCHASE_DATE: dataSource.order.properties.placed_on ? dayjs(dataSource.order.properties.placed_on) : null,
            STATUS: dataSource.order.properties.status,
            SHIP_TO_MATCHES: dataSource.order.SHIP_TO_MATCHES,
            CUSTOMER_MATCHES: dataSource.order.CUSTOMER_MATCHES,
            BUYER_CONTACT_NAME: dataSource.order.properties.contact_name,
            BUYER_CONTACT_EMAIL: dataSource.order.BUYER_CONTACT_EMAIL,
            BUYER_CONTACT_PHONE: dataSource.order.properties.phone,
            FOB: dataSource.order.FOB.value,
            FOB_TYPE: dataSource.order.FOB.type,
            REQUESTED_OR_DUE_SHIP_DATE: dataSource.order.properties.due_date ? dayjs(dataSource.order.properties.due_date) : null,
            SHIPPED_ON: dataSource.order.properties.shipped_on ? dayjs(dataSource.order.properties.shipped_on) : null,
            TOTAL_PRICE: dataSource.order.properties.total_price ?? 0.0,
            NON_PRODUCT_LINE_ITEMS: dataSource.order.properties.financial_line_items ?? [],
            USER_SELECTED_PRODUCT_LINE_ITEMS: dataSource.order.properties.line_items.map(item => ({
                ...item,
                association: item.association,
                key: item.key,
                original_quantity: item.original_quantity,
                original_unit: item.original_unit,
                PRODUCT_DESCRIPTION: item.PRODUCT_DESCRIPTION,
                PRODUCT_QUANTITY: item.PRODUCT_QUANTITY,
                PRODUCT_UNIT: item.PRODUCT_UNIT,
                PRODUCT_PRICE: item.PRODUCT_PRICE
            })),
            NOTES: dataSource.order.properties.notes
        });
    }, [dataSource]);

    useEffect(() => {
        const validState = formState.USER_SELECTED_PRODUCT_LINE_ITEMS && 
            formState.USER_SELECTED_PRODUCT_LINE_ITEMS.length > 0 &&
            formState.USER_SELECTED_PRODUCT_LINE_ITEMS.every(lineItem => lineItem.association) &&
            formState.USER_SELECTED_PRODUCT_LINE_ITEMS.every(lineItem => lineItem.PRODUCT_QUANTITY) &&
            (
                (formState.CUSTOMER_MATCHES.type === 'match' && formState.CUSTOMER_MATCHES.id) ||
                (formState.CUSTOMER_MATCHES.type === 'new' && formState.CUSTOMER_MATCHES.name)
            ) &&
            (
                (formState.CUSTOMER_MATCHES.type === 'match' && (formState.SHIP_TO_MATCHES.type === 'match' || (formState.SHIP_TO_MATCHES.type === 'new' && formState.SHIP_TO_MATCHES.name))) ||
                (formState.CUSTOMER_MATCHES.type === 'new' && (formState.SHIP_TO_MATCHES.type === 'new' && formState.SHIP_TO_MATCHES.name))
            );

            if (!validState)
                setIsFormValidMessage('Order customer or line items are in an invalid state')
            else
                setIsFormValidMessage(null);

        setIsFormValid(validState);
    }, [formState])

    const handleFormChange = (field, value) => {
        if (!isFormEdited) setIsFormEdited(true);
        setFormState(prevState => ({
            ...prevState,
            [field]: value,
        }));
    };

    const handleProductChange = (lineIndex, field, value) => {
        const updatedProductLineItems = formState.USER_SELECTED_PRODUCT_LINE_ITEMS.map((item, index) => {
            if (['PRODUCT_QUANTITY', 'PRODUCT_PRICE', 'PRODUCT_UNIT'].includes(field) && index === lineIndex) {
                return {...item, [field]: value}
            } else if (index === lineIndex) {
                // association handled specially
                return { ...item, association: field };
            }
            return item;
        });

        if (!isFormEdited) {
            setIsFormEdited(true);
            setIsLineItemsEdited(true);
        }

        setFormState(prevState => ({
            ...prevState,
            USER_SELECTED_PRODUCT_LINE_ITEMS: updatedProductLineItems,
        }));
    };

    const handleDeleteLineItem = (lineIndex) => {
        if (!isFormEdited) {
            setIsFormEdited(true);
            setIsLineItemsEdited(true);
        }

        const updatedProductLineItems = formState.USER_SELECTED_PRODUCT_LINE_ITEMS.filter((item, index) => index !== lineIndex);
    
        setFormState(prevState => ({
            ...prevState,
            USER_SELECTED_PRODUCT_LINE_ITEMS: updatedProductLineItems,
        }));
    };

    const handleAddLineItem = () => {
        if (!isFormEdited) {
            setIsFormEdited(true);
            setIsLineItemsEdited(true);
        }

        const newLineItem = { PRODUCT_QUANTITY: 0, PRODUCT_PRICE: 0, association: null}; 
    
        setFormState(prevState => ({
            ...prevState,
            USER_SELECTED_PRODUCT_LINE_ITEMS: [...prevState.USER_SELECTED_PRODUCT_LINE_ITEMS, newLineItem],
        }));
    };


    const onSendCloseEmail = (assoc_props) => {
        if (assoc_props && assoc_props.entity_type) {
            setDataSource({ ...dataSource, assoc: [...dataSource.assoc, assoc_props] });
        }
        setEmailModal(false);
        // No status change for regular email
    };

    const onSendCloseInvoice = (assoc_props) => {
        if (assoc_props && assoc_props.entity_type) {
            const dataSourceCopy = {...dataSource};
            // set datasource status to invoiced for side effects
            dataSourceCopy.order.properties.status = 'Invoiced';

            setDataSource({ ...dataSourceCopy, assoc: [...dataSource.assoc, assoc_props] });
        }
        setInvoiceModal(false);
    };
    
    const saveOrder = async () => {
        console.log(formState)
        setSaving(true);
        const result = await fetchThreadsData(
            userData.accessToken,
            {'info_type': 'save_order', 'entity': formState, 'order_id': orderId}
        ).then((r) => {
            if (r.warning) {
                if (isLineItemsEdited) {
                    // warnings are only relevant if line items are changed
                    setShowWarningAlert(r.message);
                } else {
                    setShowSuccessAlert({'order_id': r.order_id});
                }
            } else if (r.error) {
                setShowFailedAlert(r.message);
            } else if (r.order_id) {
                setShowSuccessAlert({'order_id': r.order_id});
            }

            setSaving(false);
            setIsFormEdited(false);
            setIsLineItemsEdited(false);
        });
    }

    const deleteOrder = async () => {
        setSaving(true);
        const result = await fetchThreadsData(
            userData.accessToken,
            {'info_type': 'delete_order', 'order_id': orderId}
        ).then((r) => {
            if (r.warning) {
                setShowWarningAlert(r.message);
            } else if (r.error) {
                setShowFailedAlert(r.message);
            } else if (r.order_id) {
                nav('/orders/open-orders');
            }
        });
    }

    useEffect(() => {
        const orderId = searchParams.get('order_id');
        const imported = searchParams.get('imported');

        if (!orderId) {
            console.error('Order ID not found in URL');
            return;
        }

        setOrderId(orderId);
        setImported(imported === 'true' ? true : false);

        if (abortControllerRef.current) {
            abortControllerRef.current.abort();
        }
    
        abortControllerRef.current = new AbortController();

        const fetchData = async () => {
            try {
                const data = await fetchOpenOrderData(
                    userData.accessToken,
                    { 'order_id': orderId, 'imported': imported },
                    { signal: abortControllerRef.current.signal }
                );
                console.log(data)
                setDataSource(data);
            } catch (e) {
                if (e.name !== 'AbortError') {
                    setHasError(e);
                    console.error(e);
                }
            }
        };

        fetchData();

        return () => {
            if (abortControllerRef.current) {
                abortControllerRef.current.abort();
            }
        };
    }, [searchParams, userData.accessToken, setHasError]);

    
    if (Object.keys(formState).length === 0 || !dataSource) return <Loading style={{paddingTop: 200}}/>;

    return (
        <Layout style={{ height: 'calc(100vh - 58px)', display: 'flex' }}>
            {showSuccessAlert !== null && (
                <Alert
                    message={<Link to={`/orders/order?order_id=${showSuccessAlert.order_id}`}>Order Saved</Link>}
                    type="success"
                    showIcon
                    style={{
                        position: 'absolute',
                        bottom: 10,
                        left: '50%',
                        transform: 'translateX(-50%)',
                        zIndex: 1000,
                        width: '25%'
                    }}
                />
            )}
            {showFailedAlert !== null && (
                <Alert
                    message={showFailedAlert}
                    type="error"
                    showIcon
                    closable
                    style={{
                        position: 'absolute',
                        bottom: 10,
                        left: '50%',
                        transform: 'translateX(-50%)',
                        zIndex: 1000,
                        width: '35%'
                    }}
                />
            )}
            {showWarningAlert !== null && (
                <Alert
                    message={showWarningAlert}
                    type="warning"
                    showIcon
                    closable
                    style={{
                        position: 'absolute',
                        bottom: 10,
                        left: '50%',
                        transform: 'translateX(-50%)',
                        zIndex: 1000,
                        width: '35%'
                    }}
                />
            )}
            <Content style={{paddingLeft: 15, overflowY: 'auto'}}>
                <div>
                    <Tabs defaultActiveKey="1" activeKey={activeTabKey} onChange={setActiveTabKey}>
                        <TabPane tab="Details" key="1">
                            <div style={{padding: 15, marginRight: 15, background: 'white', borderRadius: '10px'}}>
                                <OrderEntryForm
                                    key={formState.STATUS} 
                                    formState={formState}
                                    handleFormChange={handleFormChange}
                                    handleProductChange={handleProductChange}
                                    handleAddLineItem={handleAddLineItem}
                                    handleDeleteLineItem={handleDeleteLineItem}
                                    userData={userData}
                                    workflowState={{'products': dataSource.products, 'generatedOrder': formState}}
                                    allowConfigOverride={true}
                                    fullscreen={true}
                                    readOnly={imported ? true : false}
                                />
                            </div>
                        </TabPane>
                        <TabPane tab="Messages" key="2">
                            {dataSource.assoc ? dataSource.assoc.sort((a, b) => new Date(b.datetime) - new Date(a.datetime)).map(
                                (message, index) => (
                                    <div style={{marginRight: 15}}>
                                        <EmailContent
                                            key={index}
                                            message={message} 
                                            onClick={() => {}}
                                            lastDismissed={message.unread ? undefined : dayjs()}
                                        />
                                    </div>
                            )) : <Loading/>}
                        </TabPane>
                        <TabPane tab="Documents" key="3">
                            {dataSource.assoc ? (
                                <List
                                    grid={{ gutter: 16, column: 4 }}
                                    dataSource={dataSource.assoc
                                        .filter(a => (a.attachments ?? []).length > 0)
                                        .sort((a, b) => new Date(b.datetime) - new Date(a.datetime))
                                        .map(a => a.attachments.map(att => ({...att, entity_type: a.entity_type}))) 
                                        .flat()
                                    }
                                    renderItem={attachment => (
                                        <List.Item key={`${attachment.filename}-${attachment.datetime}`}>
                                        <Card 
                                            hoverable
                                            title={attachment.entity_type === 'sent_invoice' ? 'Invoice' : 'Attachment'}
                                            onClick={() => { window.open(attachment.url, '_blank'); }}
                                            style={{ width: 200, height: 250, display: 'flex', flexDirection: 'column', justifyContent: 'space-between', alignItems: 'center', margin: '15px' }}
                                        >
                                            <div style={{ textAlign: 'center', marginBottom: '10px' }}>
                                                {getAttachmentIcon(attachment)}
                                            </div>
                                            <div style={{ textAlign: 'center' }}>

                                                <Text strong>{attachment.name}</Text>
                                                <br />
                                                <Text type="secondary">{dayjs(attachment.datetime).format('MMMM D, YYYY')}</Text>
                                            </div>
                                        </Card>
                                    </List.Item>
                                    )}
                                />
                            ) : <Loading />}
                        </TabPane>
                    </Tabs>
                </div>
            </Content>
            <Sider width={120} theme="light">
            <Space direction="vertical" size="large" style={{ width: '100%', height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'space-between', padding: 15 }}>
                <div>
                    <Row style={{ paddingBottom: 15 }}>
                        <Text strong>Actions</Text>
                    </Row>
                    <Row>
                        <Tooltip title={isFormValidMessage ? isFormValidMessage : undefined}>
                            <Button type="primary" disabled={!(isFormEdited && isFormValid) || imported} onClick={saveOrder}>
                                {saving ? <LoadingOutlined /> : 'Save'}
                            </Button>
                        </Tooltip>
                    </Row>
                    <br/>
                    <Row style={{ paddingBottom: 15 }}>
                        <Tooltip title={'Email order summary'}>
                            <Button onClick={() => setEmailModal(true)}>
                                <MailOutlined/>Email
                            </Button>
                        </Tooltip>
                    </Row>
                    <Row>
                        {/* <Tooltip title={'Send Invoice'}>
                            <Button disabled={imported} onClick={() => {setInvoiceModal(true)}}>
                                <DollarCircleOutlined /> Invoice
                            </Button>
                        </Tooltip> */}
                    </Row>
                </div>
                <Row>
                    <Button danger disabled={imported} onClick={deleteOrder}>
                        {saving ? <LoadingOutlined /> : 'Delete'}
                    </Button>
                </Row>
            </Space>
            </Sider>
            <EmailEditor 
                visible={emailModal} 
                to={[formState?.BUYER_CONTACT_EMAIL]}
                orderId={orderId}
                onClose={(props)=> onSendCloseEmail(props)}
                poNum={formState.DOCUMENT_ID}
                userData={userData} 
                summary={() => generateOrderSummary(formState, dataSource, unitDisplayLabels)}
            />
            <EmailEditor 
                visible={invoiceModal} 
                to={[formState?.BUYER_CONTACT_EMAIL]}
                orderId={orderId}
                onClose={(props) => onSendCloseInvoice(props)}
                poNum={formState.DOCUMENT_ID}
                userData={userData}
                summary={null} // No summary since we'll use the invoice
                isInvoice={true} // New prop to indicate this is an invoice
            />
        </Layout>
    );
};

export default Order;
