import {convertDayjsToStrings} from '../components/utils';
import { zlibSync, unzlibSync, strToU8, strFromU8 } from 'fflate';

const serverUrl = process.env.REACT_APP_API_URL;

const fetchWorksheetData = async (accessToken, body, options = {}) => {
    // send customerIDs in body to avoid potential http 414 on too many query params if many are selected
    let headers = {
        Authorization: `Bearer ${accessToken}`,
    }

    if ((body?.data?.rows || []).length > 100000) {
        console.error('Row limit exceeded');
        return {'error': 'Row limit exceeded'};
    }

    // compress zlib defalte
    const strBody = JSON.stringify(convertDayjsToStrings(body));
    const compressedBody = zlibSync(strToU8(strBody), { level: 9 }); // max compression, poor performance
    const base64CompressedBody = btoa(strFromU8(compressedBody, true)); 

    const response = await fetch(
        `${serverUrl}/worksheets?` + new URLSearchParams({
            foo: 'bar',
        }),
        {
            headers: headers,
            body: base64CompressedBody,
            method: "POST",
            ...options
        },
    );

    if (response.status === 403) {
        throw new Error('Not Found');
    }

    const result = await response.json();

    if (result.compressed_body && response.status === 200) {
        // decompress zlib defalte
        const compressedBytes = Uint8Array.from(atob(result.compressed_body), c => c.charCodeAt(0));
        const decompressedBody = strFromU8(unzlibSync(compressedBytes));
        return JSON.parse(decompressedBody);
    } else {
        return result;
    }
};

export {fetchWorksheetData};
