// ThreadsExplorer.js
import React, { useState, useEffect } from "react";
import { Alert, Button } from 'antd';
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWandMagicSparkles } from '@fortawesome/free-solid-svg-icons';
import dayjs from "dayjs";
import ThreadsExplorerBase from './ThreadsExplorerBase';
import Workflow from './Workflow';
import { fetchThreadsData } from "../../endpoints/fetchThreadsData";
import { useOutletContext } from 'react-router-dom';
import Loading from "../Loading";

const ThreadsExplorer = (props) => {
  const { userData, setHasError } = props;
  const { accessToken } = userData;

  const [forReviewCount, setForReviewCount, products, setProducts] = useOutletContext();

  const [dataSource, setDataSource] = useState(null);
  const [loadingMore, setLoadingMore] = useState(false);
  const [pollingTaskId, setPollingTaskId] = useState(null);
  const [threadMessages, setThreadMessages] = useState([]);
  const [selectedThread, setSelectedThread] = useState(null);
  const [selectedMessage, setSelectedMessage] = useState(null);
  const [generatedOrder, setGeneratedOrder] = useState(null);
  const [workflowState, setWorkflowState] = useState({
    products: [],
    generatedOrder: null,
    selectedMessage: null,
    selectedThread: null,
  });
  const [showNewTasksOnly, setShowNewTasksOnly] = useState(true);
  const [workflowVisible, setWorkflowVisible] = useState(false);
  const [workflowConfig, setWorkflowConfig] = useState(null);
  const [showSuccessAlert, setShowSuccessAlert] = useState(null);
  const [showFailedAlert, setShowFailedAlert] = useState(null);
  const [fullScreen, setFullScreen] = useState(false);

  useEffect(() => {
    setWorkflowState({
      products: products,
      generatedOrder: generatedOrder,
      selectedMessage: selectedMessage,
      selectedThread: selectedThread,
    });
  }, [products, generatedOrder, selectedMessage, selectedThread]);

  useEffect(() => {
    if (showSuccessAlert) {
      const timer = setTimeout(() => {
        setShowSuccessAlert(null);
      }, 5000);
      return () => clearTimeout(timer);
    }
    if (showFailedAlert) {
      const timer = setTimeout(() => {
        setShowFailedAlert(null);
      }, 60000);
      return () => clearTimeout(timer);
    }
  }, [showSuccessAlert, showFailedAlert]);

  const fetchData = async (info_description, raise_anyway = false) => {
    let data;
    try {
      data = await fetchThreadsData(
        accessToken,
        { ...info_description },
      );
    } catch (e) {
      setHasError(e);
      if (raise_anyway) throw e;
      console.log('err')
      console.log(e);
    }
    if (userData.email && userData.email.includes('@pantry'))
      console.log(data)

    return data;
  };

  // Implement the methods related to actions bar and workflows
  const startCreateOrderWorkflow = async (forTrainingData = false) => {
    const currentSelectedMessage = { ...selectedMessage };
    if (forTrainingData)
      setWorkflowConfig('training');
    else
      setWorkflowConfig('save_order');

    setWorkflowVisible(true);

    // check localstorage for fullscreen preference
    const savedFullScreen = JSON.parse(localStorage.getItem('pantry-fullScreen'));
    setFullScreen(savedFullScreen || false); // Default to false if nothing is stored

    // only fetch data if it's a specific selection, otherwise generated order was fetched as a polling task on thread select
    if (currentSelectedMessage && Object.keys(currentSelectedMessage).length > 0) {

      setGeneratedOrder(null); // show loading screen
      try {
        const result = await fetchData({ 'info_type': 'generate_order', 'entity': currentSelectedMessage, 'trainingData': forTrainingData }, true);

        if (result !== 'aborted' && result['error'] !== null && result['error'] !== undefined) {
          setGeneratedOrder({ 'error': result['error'] });
        }

        setPollingTaskId(result['task_id']);
      } catch (e) {
        setGeneratedOrder({ 'error': e });
      }
    }
  };

  const startAttachWorkflow = async () => {
    setWorkflowConfig('attach');
    setWorkflowVisible(true);
  };

  const handleWorkflowFinish = async (formState, dismiss) => {    
    const currentSelectedMessage = selectedMessage ? { ...selectedMessage } : null;

    const currentFormState = {
      ...formState,
      'PRODUCT_LINE_ITEMS': [
        ...(formState['PRODUCT_LINE_ITEMS'] ?? []).map((li) => {
          return [li[0], { ...li[1], 'all_candidates': [] }]
        })
      ],
      'USER_SELECTED_PRODUCT_LINE_ITEMS': [
        ...formState['USER_SELECTED_PRODUCT_LINE_ITEMS'].map((li) => {
          return { ...li, 'all_candidates': [] }
        })
      ]
    };

    setWorkflowVisible(false);
    setGeneratedOrder(null);
    setFullScreen(false);
    setWorkflowConfig(null);

    const currentThread = selectedThread;

    let createdFromEntity = currentSelectedMessage ? currentSelectedMessage : { ...threadMessages[0], entity_type: 'email' };
    createdFromEntity = { ...createdFromEntity };
    delete createdFromEntity.body;

    const result = await fetchData({ 'info_type': workflowConfig, 'created_from_entity': createdFromEntity, 'entity': currentFormState }, false);

    if (result.error) {
      setShowFailedAlert(result.message);
    } else if (result.order_id) {
      if (dismiss) {
        handleDismissClick(currentThread);
      }
      setShowSuccessAlert({ 'order_id': result.order_id });
    }
  };

  const toggleFullScreen = () => {
    const newFullScreen = !fullScreen;
    localStorage.setItem('pantry-fullScreen', JSON.stringify(newFullScreen));
    setFullScreen(newFullScreen);
  };

  const handleWorkflowCancel = () => {
    setWorkflowVisible(false);
    setWorkflowConfig(null);
    setFullScreen(false);
  };

  const handleMessageClick = (message, selectedType) => {
    if (!workflowVisible) {
      setGeneratedOrder(null);
      setSelectedMessage({ ...message, 'body': null, 'entity_type': selectedType });
    } else
      console.log('already in workflow, cannot select new unless finish or cancel')
  };

  const handleDismissClick = (threadOverride) => {
    const thread = threadOverride ? threadOverride : selectedThread;

    const val = thread.isNew === "1" ? "0" : "1";
    const threadElement = document.getElementById(`thread-${thread.ThreadId}`);

    if (threadElement) {
      threadElement.classList.add("slide-out");
    }

    setTimeout(() => {
      fetchData({ 'info_type': 'change_isnew', 'ThreadId': thread.ThreadId, 'value': val });

      const updatedThreads = dataSource.threads.filter((t) => t.ThreadId !== thread.ThreadId);
      const dismissedThreadIndex = dataSource.threads.findIndex((t) => t.ThreadId === thread.ThreadId);

      setDataSource({
        ...dataSource,
        threads: updatedThreads
      });

      if (updatedThreads.length > 0) {
          // select the next thread below if there is one, else thread above
          const nextThreadIndex = dismissedThreadIndex < updatedThreads.length ? dismissedThreadIndex : dismissedThreadIndex - 1;
          setSelectedThread(updatedThreads[nextThreadIndex]); 
      } else
        setSelectedThread(null);
      setSelectedMessage(null);
      setForReviewCount(val === "1" ? forReviewCount + 1 : forReviewCount - 1);
    }, 200);
  };

  const dedupThreads = (existingThreads, newThreads) => {
    const mergedThreads = [...existingThreads, ...(newThreads ? newThreads : [])];
    const uniqueThreads = [];
    const threadIds = new Set();

    for (const thread of mergedThreads) {
      if (!threadIds.has(thread.ThreadId)) {
        uniqueThreads.push(thread);
        threadIds.add(thread.ThreadId);
      }
    }

    return uniqueThreads;
  };

  const loadMore = () => {
    setLoadingMore(true);
    fetchData({ 'info_type': 'threads', 'paginationKey': dataSource.paginationKey, 'showDismissed': !showNewTasksOnly })
      .then((newData) => {
        if (newData) {
          setDataSource({
            ...dataSource,
            threads: dedupThreads(dataSource.threads, newData.threads).sort((a, b) => dayjs(b.Timestamp).diff(dayjs(a.Timestamp))),
            paginationKey: newData.paginationKey
          });
        }
      })
      .finally(() => setLoadingMore(false));
  };

  useEffect(() => {
    const maxPollingTime = 300000; // Maximum polling time of 5 mins
    const startTime = Date.now();
    let pollingInterval;

    const pollTask = async () => {
      if (pollingTaskId !== null && Date.now() - startTime < maxPollingTime) {
        try {
          const task = await fetchData({ 'info_type': 'task_poll', 'task_id': pollingTaskId });

          if (task === 'aborted') {
            setGeneratedOrder(null);
            setPollingTaskId(null);
          } else if (task['task'].error !== undefined && task['task'].error !== null) {
            console.error('Task error:', task.error);
            setGeneratedOrder({ error: task['task'].error });
            setPollingTaskId(null);
          } else if (task['task'].complete) {
            setGeneratedOrder(task['task']['result']);
            setPollingTaskId(null);
          } else {
            pollingInterval = setTimeout(pollTask, 1000);
          }
        } catch (error) {
          console.error('Error polling task:', error);
          setGeneratedOrder({ 'error': error });
          setPollingTaskId(null);
        }
      } else {
        setPollingTaskId(null);
      }
    };

    pollTask();

    return () => clearTimeout(pollingInterval);
  }, [pollingTaskId]);

  const renderActionsBar = () => {
    return (
      <div>
        {selectedThread && (
          <Button
            style={{ margin: '10px', display: 'block' }}
            onClick={() => handleDismissClick()}
          >
            {selectedThread && selectedThread.isNew === "1" ? 'Dismiss Task' : 'Undismiss Task'}
          </Button>
        )}
        {selectedThread && threadMessages !== null && threadMessages.filter((m) => m.web_order).length === 0 && (
          <Button style={{ margin: '10px' }} onClick={startAttachWorkflow}>
            Attach to Order
          </Button>
        )}
        {selectedThread && threadMessages !== null && threadMessages.filter((m) => m.web_order).length > 0 && (
          <Button type="primary" style={{ margin: '10px' }} onClick={() => { }}>
            <Link to={{
              pathname: '/orders/order',
              search: `?order_id=${threadMessages.filter((m) => m.web_order)[0].message_id}`
            }} >View Order</Link>
          </Button>
        )}
        {selectedThread && threadMessages !== null && threadMessages.filter((m) => m.web_order).length === 0 && (
          <Button type="primary" style={{ margin: '10px' }} onClick={() => startCreateOrderWorkflow(false)}>
            <FontAwesomeIcon icon={faWandMagicSparkles} />&nbsp;Create Order
          </Button>
        )}
        {selectedThread && selectedMessage && userData.email && userData.email.includes('arlo@pantry') && (
          <Button style={{ margin: '10px' }} onClick={() => startCreateOrderWorkflow(true)}>
            <FontAwesomeIcon icon={faWandMagicSparkles} />&nbsp;Create Training Data
          </Button>
        )}
      </div>
    );
  };

  return (
    <>
      <ThreadsExplorerBase
        userData={userData}
        fetchData={fetchData}
        renderActionsBar={renderActionsBar}
        onMessageClick={handleMessageClick}
        onThreadSelect={setSelectedThread}
        pollingTaskId={pollingTaskId}
        setPollingTaskId={setPollingTaskId}
        generatedOrder={generatedOrder}
        setGeneratedOrder={setGeneratedOrder}
        selectedMessage={selectedMessage}
        setSelectedMessage={setSelectedMessage}
        selectedThread={selectedThread}
        setSelectedThread={setSelectedThread}
        dataSource={dataSource}
        setDataSource={setDataSource}
        showNewTasksOnly={showNewTasksOnly}
        setShowNewTasksOnly={setShowNewTasksOnly}
        threadMessages={threadMessages}
        setThreadMessages={setThreadMessages}
        loadingMore={loadingMore}
        setLoadingMore={setLoadingMore}
        loadMore={loadMore}
        products={products}
        setHasError={setHasError}
        showSuccessAlert={showSuccessAlert}
        showFailedAlert={showFailedAlert}
        setShowFailedAlert={setShowFailedAlert}
        workflowComponent={
            <Workflow
                userData={userData}
                workflowState={workflowState}
                configType={workflowConfig}
                onFinish={handleWorkflowFinish}
                onCancel={handleWorkflowCancel}
                fullscreen={fullScreen}
                toggleFullScreen={toggleFullScreen}
            />
        }
        workflowVisible={workflowVisible}
        fullScreen={fullScreen}
      />
    </>
  );
};

export default ThreadsExplorer;