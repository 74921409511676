import React, { useState, useMemo, useEffect, useRef} from "react";
import {
  Modal,
  Button,
  Checkbox,
  Radio,
  Select,
  Typography,
  Space,
  Divider,
  List,
  Row,
  Col
} from "antd";
import {
  FileTextOutlined,
  FileExcelOutlined,
  FontSizeOutlined,
  FieldBinaryOutlined,
  PlayCircleOutlined
} from "@ant-design/icons";
import * as XLSX from "xlsx";
import dayjs from "dayjs";
import { enrichmentConfigs } from "./EnrichmentFlow";

/**
 * Icon map for each column_type
 */
const colTypeToIcon = {
  text: <FontSizeOutlined style={{ color: "#918d8d", marginRight: 4 }} />,
  number: <FieldBinaryOutlined style={{ color: "#918d8d", marginRight: 4 }} />,
  enrichment: <PlayCircleOutlined style={{ color: "#918d8d", marginRight: 4 }} />
};

/**
 * Collect all subkeys for each enrichment column whose rows have object/dict values.
 * We only scan a limited number of rows for performance; this is just to discover potential subkeys.
 */
function gatherEnrichmentSubkeys(columns, rows, maxScanRows = 200) {
  const subkeysMap = {}; // colKey -> Set of subkeys
  columns.forEach((col) => {
    if (col.column_type === "enrichment") {
      let subkeySet = new Set();
      for (let i = 0; i < rows.length && i < maxScanRows; i++) {
        const val = rows[i][col.key];
        if (val && typeof val === "object" && !Array.isArray(val)) {
          // collect all top-level keys
          Object.keys(val).forEach((k) => subkeySet.add(k));
        }
      }
      if (subkeySet.size > 0) {
        subkeysMap[col.key] = Array.from(subkeySet).sort();
      }
    }
  });
  return subkeysMap;
}

/**
 * Build the flattened list of "exportable fields" for columns mode:
 * - For normal columns (text/number), there's just 1 item
 * - For enrichment columns:
 *    - 1 item for the "entire" cell value
 *    - extra items for each discovered subkey, labeled e.g. "ColumnName.key"
 *
 * We'll store each item as { label, value, disabled, isSubfield, subKey, column }.
 * The `value` is what we'll keep in the "selectedColumns" array.
 */
function buildExportableFields(columns, rows) {
  const subkeysMap = gatherEnrichmentSubkeys(columns, rows);
  const exportable = [];

  // Filter out rowIndex, addColumn from the UI
  const realColumns = columns.filter(
    (c) => c.key !== "rowIndex" && c.key !== "addColumn"
  );

  realColumns.forEach((col) => {
    const disabled = !(
      col.column_type === "text" ||
      col.column_type === "number" ||
      col.column_type === "enrichment"
    );

    // Add the main column if it does not have dict values
    if (col.column_type === "text" || col.column_type === "number" || !subkeysMap[col.key]) {
      exportable.push({
        label: col.name,
        value: col.key, // top-level value
        disabled,
        isSubfield: false,
        subKey: null,
        column: col
      });
    }

    // If it's an enrichment column with discovered subkeys, add them
    if (col.column_type === "enrichment" && subkeysMap[col.key]) {
      subkeysMap[col.key].forEach((subK) => {
        exportable.push({
          label: `${col.name}.${subK}`,
          value: `${col.key}::${subK}`, // referencing subfield
          disabled: false,
          isSubfield: true,
          subKey: subK,
          column: col
        });
      });
    }
  });

  return exportable;
}

const ExportFlow = ({
  open,
  onClose,
  columns,
  rows
}) => {
  // Which export mode: "columns" (normal) or "rows" (transpose)
  const [exportMode, setExportMode] = useState("columns");
  const colsInitialized = useRef(false);

  // Distinct enrichment types that can be exported as rows
  const enrichedColumnsByType = useMemo(() => {
    const map = {};
    columns.forEach((col) => {
      if (
        col.column_type === "enrichment" &&
        col.enrichment_config?.exportConfig?.exportAsCols
      ) {
        const etype = col.enrichment_config.type; // e.g. 'quantity_sold'
        if (!map[etype]) map[etype] = [];
        map[etype].push(col);
      }
    });
    return map;
  }, [columns]);

  const availableEnrichmentTypes = useMemo(
    () => Object.keys(enrichedColumnsByType),
    [enrichedColumnsByType]
  );

  //////////////////////////////////////////////////////////////////////////////////
  // "COLUMNS" mode logic
  //////////////////////////////////////////////////////////////////////////////////

  const exportableColumnFields = useMemo(() => {
    return buildExportableFields(columns, rows);
  }, [columns, rows]);

  const defaultSelectedColumns = useMemo(() => {
    const selected = [];
    exportableColumnFields.forEach((item) => {
    //   if (!item.isSubfield) {
    //     selected.push(item.value);
    //   }

        // for now just have all columns selected by default
        selected.push(item.value);
    });
    return selected;
  }, [exportableColumnFields]);

  const [selectedColumns, setSelectedColumns] = useState([]);

  useEffect(() => {
    if (open && exportableColumnFields.length && !colsInitialized.current) {
      setSelectedColumns(defaultSelectedColumns);
      colsInitialized.current = true;
    }
    if (!open) {
        colsInitialized.current = false; // reset for next open
    }
  }, [open, exportableColumnFields, defaultSelectedColumns]);

  // The set of chosen "exportable fields" for columns mode
  const chosenExportFieldsColumnsMode = useMemo(() => {
    return exportableColumnFields.filter((f) => selectedColumns.includes(f.value));
  }, [exportableColumnFields, selectedColumns]);

  //////////////////////////////////////////////////////////////////////////////////
  // "ROWS" mode logic
  //////////////////////////////////////////////////////////////////////////////////

  const [selectedEnrichmentType, setSelectedEnrichmentType] = useState(null);

  // The normal text/number columns
  const textNumberCols = useMemo(() => {
    return columns.filter(
      (col) =>
        (col.column_type === "text" || col.column_type === "number") &&
        col.key !== "rowIndex" &&
        col.key !== "addColumn"
    );
  }, [columns]);

  // Normal columns to display in "rows" mode
  const [selectedColumnsRowsMode, setSelectedColumnsRowsMode] = useState([]);

  useEffect(() => {
    if (textNumberCols.length) {
      setSelectedColumnsRowsMode(textNumberCols.map((c) => c.key));
    }
  }, [textNumberCols]);

  // The user-chosen special keys from the config
  const [selectedSpecialKeys, setSelectedSpecialKeys] = useState([]);

  // Which columns of this type do we transpose? By default, all
  const [selectedEnrichedColsToTranspose, setSelectedEnrichedColsToTranspose] = useState([]);

  // If user picks a new enrichmentType, default the transposed columns & special keys
  useEffect(() => {
    if (!selectedEnrichmentType) {
      setSelectedEnrichedColsToTranspose([]);
      setSelectedSpecialKeys([]);
      return;
    }
    const eCols = enrichedColumnsByType[selectedEnrichmentType] || [];
    const allKeys = eCols.map((col) => col.key);
    setSelectedEnrichedColsToTranspose(allKeys);

    if (eCols.length > 0) {
      const exportAsColsObj = eCols[0].enrichment_config?.exportConfig?.exportAsCols || {};
      // We'll pick all special keys from the first column plus forced ones
      setSelectedSpecialKeys(Object.keys(exportAsColsObj));
    } else {
      setSelectedSpecialKeys([]);
    }
  }, [selectedEnrichmentType, enrichedColumnsByType]);

  // The "special columns" for the chosen type (from the *first* col of that type)
  const specialExportDefs = useMemo(() => {
    if (!selectedEnrichmentType) return {};
    const eCols = enrichedColumnsByType[selectedEnrichmentType] || [];
    if (!eCols.length) return {};
    return eCols[0].enrichment_config.exportConfig?.exportAsCols || {};
  }, [selectedEnrichmentType, enrichedColumnsByType]);

  // We'll add two forced columns: one for the dict value and one for the dict key
  // named after the enrichment type (e.g. "Quantity Sold" / "Quantity Sold.Breakout")
  const forcedValueKey = "__FORCED_VALUE__";
  const forcedValueBreakoutKey = "__FORCED_VALUE_BREAKOUT__";

  const forcedValueLabel = selectedEnrichmentType
    ? enrichmentConfigs[selectedEnrichmentType]?.name || "Enriched Value"
    : "Enriched Value";
  const forcedValueBreakoutLabel = forcedValueLabel + ".Breakout";

  const allSpecialKeysList = useMemo(() => {
    const userKeys = Object.keys(specialExportDefs).map((k) => ({
      key: k,
      label: specialExportDefs[k].name || k,
      disabled: false
    }));
    // Insert forced columns (disabled so user can't unselect them)
    if (selectedEnrichmentType) {
      userKeys.unshift({
        key: forcedValueBreakoutKey,
        label: forcedValueBreakoutLabel,
        disabled: true
      });
      userKeys.unshift({
        key: forcedValueKey,
        label: forcedValueLabel,
        disabled: true
      });
    }
    return userKeys;
  }, [specialExportDefs, selectedEnrichmentType, forcedValueLabel, forcedValueBreakoutLabel]);

  // Ensure forced columns are always in the array
  useEffect(() => {
    if (!selectedEnrichmentType) return;
    setSelectedSpecialKeys((prev) => {
      let next = [...prev];
      if (!next.includes(forcedValueKey)) {
        next.unshift(forcedValueKey);
      }
      if (!next.includes(forcedValueBreakoutKey)) {
        next.splice(1, 0, forcedValueBreakoutKey);
      }
      return next;
    });
  }, [selectedEnrichmentType, forcedValueKey, forcedValueBreakoutKey]);

  // "Rows" mode is disabled if no enrichment type selected or no columns chosen
  const isRowsModeDisabled = useMemo(() => {
    if (exportMode !== "rows") return false;
    if (!selectedEnrichmentType) return true;
    if (!selectedEnrichedColsToTranspose.length) return true;
    return false;
  }, [exportMode, selectedEnrichmentType, selectedEnrichedColsToTranspose]);

  //////////////////////////////////////////////////////////////////////////////////
  // Building the final exported 2D array
  //////////////////////////////////////////////////////////////////////////////////

  const buildExportData = (fileType) => {
    if (exportMode === "columns") {
      // "columns" mode -> we use chosenExportFieldsColumnsMode in order
      const header = chosenExportFieldsColumnsMode.map((item) => item.label);
      const data = [header];

      rows.forEach((r) => {
        const rowArr = chosenExportFieldsColumnsMode.map((item) => {
          const { column, isSubfield, subKey } = item;
          const rawVal = r[column.key];
          if (rawVal == null) return "";
          if (!isSubfield) {
            if (typeof rawVal === "object" && !Array.isArray(rawVal)) {
              return JSON.stringify(rawVal);
            }
            return rawVal;
          } else {
            if (typeof rawVal === "object" && rawVal !== null) {
              return rawVal[subKey] ?? "";
            }
            return "";
          }
        });
        data.push(rowArr);
      });
      return data;
    } else {
      // "ROWS" (transpose) mode
      // The final columns:
      //   1) The normal data columns (selectedColumnsRowsMode)
      //   2) The user-chosen special keys from each column's exportAsCols (excluding forced placeholders)
      //   3) The forced value column (dict value)
      //   4) The forced breakout column (dict key)

      const normalCols = columns.filter((c) =>
        selectedColumnsRowsMode.includes(c.key)
      );

      const transposedCols = columns.filter(
        (c) =>
          selectedEnrichedColsToTranspose.includes(c.key) &&
          c.enrichment_config?.type === selectedEnrichmentType
      );

      // Filter out placeholders so we can handle them separately
      const realUserChosenSpecialKeys = selectedSpecialKeys.filter(
        (k) => k !== forcedValueKey && k !== forcedValueBreakoutKey
      );

      const header = [
        ...normalCols.map((c) => c.name),
        ...realUserChosenSpecialKeys.map((k) => specialExportDefs[k].name),
        forcedValueLabel,
        forcedValueBreakoutLabel
      ];

      const data = [header];

      rows.forEach((row) => {
        transposedCols.forEach((tc) => {
          const val = row[tc.key];
          if (val && typeof val === "object" && !Array.isArray(val)) {
            // For dict values, we create one row per key, unless key=="Name" (default placeholder value)
            const dictKeys = Object.keys(val).filter((k) => k !== "Name");
            if (dictKeys.length) {
              dictKeys.forEach((dictK) => {
                const line = [];
                // Normal columns
                normalCols.forEach((nc) => {
                  line.push(row[nc.key] == null ? "" : row[nc.key]);
                });
                // User-chosen special keys
                realUserChosenSpecialKeys.forEach((sk) => {
                  const def = tc.enrichment_config?.exportConfig?.exportAsCols?.[sk];
                  line.push(def?.value ?? "");
                });
                // forced value (dict value) + forced breakout (dict key)
                line.push(val[dictK] ?? "");
                line.push(dictK);
                data.push(line);
              });
            } else {
              // If no dict keys remain after filtering out "Name", treat as empty object => single row
              const line = [];
              normalCols.forEach((nc) => {
                line.push(row[nc.key] == null ? "" : row[nc.key]);
              });
              realUserChosenSpecialKeys.forEach((sk) => {
                const def = tc.enrichment_config?.exportConfig?.exportAsCols?.[sk];
                line.push(def?.value ?? "");
              });
              line.push("");
              line.push("");
              data.push(line);
            }
          } else {
            // not an object => single row
            const line = [];
            normalCols.forEach((nc) => {
              line.push(row[nc.key] == null ? "" : row[nc.key]);
            });
            realUserChosenSpecialKeys.forEach((sk) => {
              const def = tc.enrichment_config?.exportConfig?.exportAsCols?.[sk];
              line.push(def?.value ?? "");
            });
            line.push(val == null ? "" : val);
            line.push("");
            data.push(line);
          }
        });
      });

      return data;
    }
  };

  const downloadFile = (fileType) => {
    const data = buildExportData(fileType);
    if (!data || !data.length) return;

    if (fileType === "csv") {
      const csvRows = data.map((rowArr) =>
        rowArr
          .map((cell) => {
            if (cell == null) cell = "";
            const cellStr = String(cell).replace(/"/g, '""');
            return `"${cellStr}"`;
          })
          .join(",")
      );
      const csvContent = "\uFEFF" + csvRows.join("\r\n");
      const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
      const url = URL.createObjectURL(blob);

      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `pantry_export_${dayjs().format('YYYY-MM-DD')}.csv`);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(url);
    } else {
      const wb = XLSX.utils.book_new();
      const ws = XLSX.utils.aoa_to_sheet(data);
      XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
      const wbout = XLSX.write(wb, { type: "array", bookType: "xlsx" });

      const blob = new Blob([wbout], { type: "application/octet-stream" });
      const url = URL.createObjectURL(blob);

      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `pantry_export_${dayjs().format('YYYY-MM-DD')}.xlsx`);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(url);
    }
  };

  const hasColumnsAsRowsOption = availableEnrichmentTypes.length > 0;

  const handleModalCancel = () => {
    setExportMode("columns");
    setSelectedEnrichmentType(null);
    onClose();
  };

  return (
    <Modal
      title="Export Worksheet"
      open={open}
      onCancel={handleModalCancel}
      footer={null}
      width={720}
    >
      {hasColumnsAsRowsOption && (
        <Radio.Group
          onChange={(e) => {
            setExportMode(e.target.value);
            if (e.target.value === "columns") {
              setSelectedEnrichmentType(null);
            }
          }}
          value={exportMode}
          style={{ marginBottom: 16, marginTop: 8 }}
        >
          <Radio value="columns">Export rows normally</Radio>
          <Radio value="rows">Export columns as rows</Radio>
        </Radio.Group>
      )}

      {!hasColumnsAsRowsOption && (
        <Typography.Text type="secondary">
          Export columns as rows not available for this worksheet
        </Typography.Text>
      )}

      <Divider />

      {/* ================== "COLUMNS" MODE ================== */}
      {exportMode === "columns" && (
        <>
          <Typography.Title level={5}>Export columns</Typography.Title>
          <List
            bordered
            style={{ maxHeight: 300, overflowY: "auto", marginBottom: 16 }}
            dataSource={exportableColumnFields}
            renderItem={(item) => {
              const { disabled, value, label, column, isSubfield } = item;
              const checked = selectedColumns.includes(value);
              const subfieldStyle = isSubfield ? {} : {};

              return (
                <List.Item>
                  <Checkbox
                    disabled={disabled}
                    checked={checked}
                    onChange={(e) => {
                      if (e.target.checked) {
                        setSelectedColumns((prev) => [...prev, value]);
                      } else {
                        setSelectedColumns((prev) => prev.filter((x) => x !== value));
                      }
                    }}
                  >
                    <span style={{ display: "flex", alignItems: "center" }}>
                      {colTypeToIcon[column.column_type]}
                      <span style={subfieldStyle}>{label}</span>
                      {disabled && (
                        <Typography.Text type="secondary" style={{ marginLeft: 8 }}>
                          (unsupported type)
                        </Typography.Text>
                      )}
                    </span>
                  </Checkbox>
                </List.Item>
              );
            }}
          />
        </>
      )}

      {/* ================== "ROWS" MODE ================== */}
      {exportMode === "rows" && (
        <Space direction="vertical" style={{ width: "100%" }}>
          <div>
            <Typography.Title level={5}>Column enrichment type to convert to rows</Typography.Title>
            <Select
              style={{ width: "100%" }}
              placeholder="Select an enrichment type"
              value={selectedEnrichmentType || undefined}
              onChange={(val) => setSelectedEnrichmentType(val)}
              allowClear
              options={availableEnrichmentTypes.map((etype) => ({
                label: enrichmentConfigs[etype]?.name || etype,
                value: etype
              }))}
            />
          </div>

          {selectedEnrichmentType && (
            <>
              <div>
                <Typography.Text>Enriched columns to convert to rows</Typography.Text>
                <List
                  bordered
                  style={{ maxHeight: 200, overflowY: "auto" }}
                  dataSource={enrichedColumnsByType[selectedEnrichmentType]}
                  renderItem={(col) => (
                    <List.Item>
                      <Checkbox
                        checked={selectedEnrichedColsToTranspose.includes(col.key)}
                        onChange={(e) => {
                          if (e.target.checked) {
                            setSelectedEnrichedColsToTranspose((prev) => [...prev, col.key]);
                          } else {
                            setSelectedEnrichedColsToTranspose((prev) =>
                              prev.filter((k) => k !== col.key)
                            );
                          }
                        }}
                      >
                        {colTypeToIcon[col.column_type]}
                        {col.name}
                      </Checkbox>
                    </List.Item>
                  )}
                />
              </div>

              <Divider />

              <Typography.Title level={5}>Export columns</Typography.Title>
              <Typography.Text>Data columns</Typography.Text>
              <List
                bordered
                style={{ maxHeight: 200, overflowY: "auto", marginTop: 8 }}
                dataSource={textNumberCols}
                renderItem={(col) => (
                  <List.Item>
                    <Checkbox
                      checked={selectedColumnsRowsMode.includes(col.key)}
                      onChange={(e) => {
                        if (e.target.checked) {
                          setSelectedColumnsRowsMode((prev) => [...prev, col.key]);
                        } else {
                          setSelectedColumnsRowsMode((prev) =>
                            prev.filter((k) => k !== col.key)
                          );
                        }
                      }}
                    >
                      {colTypeToIcon[col.column_type]}
                      {col.name}
                    </Checkbox>
                  </List.Item>
                )}
              />

              <div style={{ marginTop: 20 }}>
                <Typography.Text>Export enrichment column data</Typography.Text>
                <List
                  bordered
                  style={{ maxHeight: 200, overflowY: "auto", marginTop: 8 }}
                  dataSource={allSpecialKeysList}
                  renderItem={(item) => (
                    <List.Item>
                      <Checkbox
                        checked={selectedSpecialKeys.includes(item.key)}
                        disabled={item.disabled}
                        onChange={(e) => {
                          if (e.target.checked) {
                            setSelectedSpecialKeys((prev) => [...prev, item.key]);
                          } else {
                            setSelectedSpecialKeys((prev) =>
                              prev.filter((k) => k !== item.key)
                            );
                          }
                        }}
                      >
                        {item.label}
                      </Checkbox>
                    </List.Item>
                  )}
                />
              </div>
            </>
          )}
        </Space>
      )}

      <Divider />

      <Row justify="end" gutter={8}>
        <Col>
          <Button
            icon={<FileTextOutlined />}
            disabled={exportMode === "rows" && isRowsModeDisabled}
            onClick={() => downloadFile("csv")}
          >
            Export CSV
          </Button>
        </Col>
        <Col>
          <Button
            icon={<FileExcelOutlined />}
            disabled={exportMode === "rows" && isRowsModeDisabled}
            onClick={() => downloadFile("xlsx")}
            type="primary"
          >
            Export Excel
          </Button>
        </Col>
      </Row>
    </Modal>
  );
}

export default ExportFlow;