import React, {useEffect, useRef, useState} from "react";

import Loading from "../Loading";
import {withAuthenticationRequired} from "@auth0/auth0-react";
import {Link, Outlet} from "react-router-dom";

const sideColor = '#F5F5F5'

const WorksheetContainer = (props) => {
    const {userData, hasError, setHasError} = props;
    
    return (
        <div className="worksheet">
            <Outlet />
        </div>

    );
}

export default withAuthenticationRequired(WorksheetContainer, {
    onRedirecting: () => <Loading/>,
});