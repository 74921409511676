import React, {useEffect, useState} from 'react';
import { StyleProvider } from '@ant-design/cssinjs';
import {Typography, ConfigProvider} from 'antd';
import {BrowserRouter, Link, Route, Routes, useNavigate} from 'react-router-dom';
import Auth0ProviderWithHistory from './auth/auth0ProviderWithHistory';
import AppLayout from "./AppLayout";
import Processing from "./components/Onboarding/Processing";
import logo from "./logo.svg";
import {useAuth0} from "@auth0/auth0-react";
import { AnalyticsBrowser } from '@segment/analytics-next';
import { isMobile } from "react-device-detect";
import Error500 from "./components/error/Error500";
import NotFound from './components/error/NotFound';
import SettingsContainer from "./components/Settings/SettingsContainer";
import Integrations from './components/Settings/Integrations';
import Dashboard from './components/DemandPlanning/Dashboard';
import WithDefaultParam from './components/WithDefaultParam';
import OpenOrderExplorer from './components/Orders/OpenOrderExplorer';
import Order from './components/Orders/Order';
import OrderContainer from './components/Orders/OrderContainer';
import ThreadsExplorer from './components/Orders/ThreadsExplorer';
import { Settings } from './components/Settings/OrderSettings';
import PublicOrder from './components/Orders/PublicOrder';
import Cookies from 'js-cookie';
import CustomerManager from './components/Orders/CustomerManager';
import IngestLog from './components/Settings/IngestLog';
import CustomerReport from './components/Orders/CustomerReport';
import WorksheetsHomePage from './components/Orchestrate/WorksheetHome';
import WorksheetManager from './components/Orchestrate/WorksheetManager';
import WorksheetContainer from './components/Orchestrate/WorksheetContainer';

const segmentKey = process.env.REACT_APP_SEGMENT_KEY;
const reactEnv = process.env.REACT_APP_ENV;

const colorPrimary = '#4fad96';

const analytics = AnalyticsBrowser.load({writeKey: segmentKey});

class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError(error) {
        // Update state so the next render will show the fallback UI.
        return { hasError: true };
    }

    componentDidCatch(error, info) {
        // You can also log the error to an error reporting service
        console.log(error, info);
    }

    render() {
        if (this.state.hasError) {

            if (reactEnv === 'prod') {
                analytics.track(`Error!`);
            }
            return <Error500/>;
        }

        return this.props.children;
    }
}

const getOrgId = () => {
    const urlParams = new URLSearchParams(window.location.search);
    const myParam = urlParams.get('portal');
    if (myParam !== null) {
        return myParam;
    };
    // persist in local storage so that hard refreshes won't force users to pick their org again
    // we clear local storage when they log out so they can pick their desired org then
    let org_id = window.localStorage.getItem("pantry-ai-current-org-id")
    return org_id;
}

const App = () => {
    return (
        <ConfigProvider
            //locale={'en-US'}
            theme={{
                token: {
                    "colorPrimary": colorPrimary,
                },
            }}
        >
            <StyleProvider hashPriority="high">
            <BrowserRouter>
                <ErrorBoundary>
                    <Auth0ProviderWithHistory org_id={getOrgId()} >
                        <ContentWrapper/>
                    </Auth0ProviderWithHistory>
                </ErrorBoundary>
            </BrowserRouter>
            </StyleProvider>
        </ConfigProvider>
    );
};


const ContentWrapper = (props) => {
    const [userData, setUserData] = useState(null);
    const {getAccessTokenSilently, user, isLoading, isAuthenticated, loginWithRedirect} = useAuth0();
    const [accessToken, setAccessToken] = useState(null);
    const [hasError, setHasError] = useState(false);

    let content;
    if (isMobile) {
        content = (
            <div style={{textAlign: "center", paddingTop: 100}}>
                <img height={'70'} src={logo} alt="Logo"/>
                <br/>
                <Typography.Text>Currently, our app is designed only for desktop & laptop computers!</Typography.Text>
                <br/>
                <Typography.Text>Please use your computer or make the browser window larger :)</Typography.Text>
            </div>
        )
    } else if (hasError) {

        if (reactEnv === 'prod') {
            analytics.track(`Error! ${userData.email}`);
        }

        if (hasError.message && hasError.message === 'Not Found') {
            content = <NotFound userData={userData}/>;
        } else
            content =  <Error500 userData={userData}/>;

    } else {
        content = (
            <Routes>
                {/* we should probably be using this for more idiomatic invitation auth0 flows, but this didn't work for some reason */}
                {/* <Route path="/login" element={<h1>Login</h1>}/> */}
                <Route path="/" element={<AppLayout userData={userData} hasError={hasError} setHasError={setHasError}/>}>
                    <Route index element={<Dashboard userData={userData} hasError={hasError} setHasError={setHasError}/>}/>                    
                    <Route path="orders" element={<OrderContainer userData={userData} hasError={hasError} setHasError={setHasError}/>}>
                        <Route 
                            path="open-orders" 
                            element={<OpenOrderExplorer userData={userData} hasError={hasError} setHasError={setHasError} />}
                        />
                        <Route
                            path="order" 
                            element={<Order userData={userData} hasError={hasError} setHasError={setHasError} />}
                        />
                        <Route 
                            path="threads" 
                            element={<ThreadsExplorer userData={userData} hasError={hasError} setHasError={setHasError} />}
                        />
                        <Route 
                            path="customers" 
                            element={<CustomerManager userData={userData} hasError={hasError} setHasError={setHasError} />}
                        />
                        <Route
                            path="customer" 
                            element={<CustomerReport userData={userData} hasError={hasError} setHasError={setHasError} />}
                        />
                    </Route>
                    <Route path="orchestrate" element={<WorksheetContainer userData={userData} hasError={hasError} setHasError={setHasError}/>}>
                        <Route path="home" element={<WorksheetsHomePage userData={userData} hasError={hasError} setHasError={setHasError}/>}/>
                        <Route path="worksheet" element={<WorksheetManager userData={userData} hasError={hasError} setHasError={setHasError}/>}/>
                    </Route>
                    <Route path="settings" element={<SettingsContainer userData={userData} hasError={hasError} setHasError={setHasError}/>}>
                        <Route 
                            path="order-settings" 
                            element={<Settings userData={userData} hasError={hasError} setHasError={setHasError} />}
                        />
                        <Route 
                            path="event-log" 
                            element={<IngestLog userData={userData} hasError={hasError} setHasError={setHasError} />}
                        />
                        <Route 
                            path="integrations" 
                            element={<Integrations userData={userData} hasError={hasError} setHasError={setHasError} />}
                        />
                    </Route>
                </Route>
                <Route path="/processing" element={<Processing userData={userData} />}/>
                <Route path="/order" element={<PublicOrder/>}/>
                <Route path="*" element={<NotFound userData={userData}/>} />
            </Routes>
        )
    }

    if (user !== undefined) {
        const { email } = user;

        if (accessToken === null) {
            (async () => {
                const token = await getAccessTokenSilently();
                setAccessToken(token);
            })();
        }

        if ( accessToken !== null) {

            // const savedSettings = await fetchUserSettings(accessToken);

            let parsed = JSON.parse(atob(accessToken.split('.')[1]));
            let current_account = parsed['org_name'];
            let setupState = parsed['custom_setupState'];  
            let openOrders = parsed['custom_openOrders'];
            let orgDisplayName = parsed['custom_org_display_name'];

            // persist in local storage so that hard refreshes won't force users to pick their org again
            // we clear local storage when they log out so they can pick their desired org then
            window.localStorage.setItem("pantry-ai-current-org-id", parsed['org_id']);
            const cookieOptions = { expires: 7 };
            if (window.location.hostname !== 'localhost') {
                cookieOptions.domain = 'pantrysoftware.com';
            }

            Cookies.set(
                'pantry-ai-access-token', 
                accessToken, 
                cookieOptions
            );

            // let authed_accounts = savedSettings === null ? [current_account] : savedSettings['authorized_accounts'];
            if (userData === null) {
                analytics.identify(email)
                setUserData({
                    email: email,
                    accessToken: accessToken,
                    openOrders: openOrders,
                    setupState: setupState,
                    currentAccount: current_account,
                    currentAccountDisplay: orgDisplayName,
                    authorizedAccounts: [], // unused now; // authed_accounts
                });
            }
        }
    }

    return content
}

export default App;