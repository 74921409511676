import React, { useState, useMemo, useEffect } from "react";
import { 
    Modal, 
    Typography, 
    Select, 
    Input, 
    Row, 
    Col, 
    List, 
    Card, 
    Button,
    Checkbox
} from 'antd';
import {
    LoadingOutlined, 
    MergeOutlined, 
    ProfileOutlined,
    PlusOutlined,
    DeleteOutlined,
    FontSizeOutlined,
    FieldBinaryOutlined,
    PlayCircleOutlined,
    HomeOutlined,
    InfoCircleOutlined,
    CheckCircleOutlined,
    DownloadOutlined
} from '@ant-design/icons';
import { faWandMagicSparkles } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import TextArea from "antd/es/input/TextArea";
import { Collapse } from 'antd';
import { fetchWorksheetData } from "../../endpoints/fetchWorksheetData";
import { formatTimeAgo } from "../utils";
import dayjs from "dayjs";

// Helper to get icon for a given column type
const getColumnTypeIcon = (colType) => {
    if (colType === 'text') {
        return <FontSizeOutlined style={{ color: '#918d8d' }} />;
    } else if (colType === 'number') {
        return <FieldBinaryOutlined style={{ color: '#918d8d' }} />;
    } else if (colType === 'enrichment') {
        return <PlayCircleOutlined style={{ color: '#918d8d' }} />;
    } else {
        return null;
    }
};

// Define config for each enrichment type
const enrichmentConfigs = {
    quantity_sold: {
        name: 'Quantity Sold',
        description: 'Lookup the quantity sold of product for imported locations',
        column_name: 'Qty Sold',
        column_type: 'enrichment',
        data_type: 'number',
        category: 'Locations'
    },
    aggregate: {
        name: 'Cross Reference Lookup',
        description: 'Add a column with aggregated data from a worsheet by value exact match',
        column_name: 'Aggregated Values',
        category: 'Aggregate',
        column_type: 'enrichment',
    },
    transform: {
        name: 'Transform',
        description: 'Transform data using AI',
        category: 'Transform',
        column_name: 'Transformed Values',
        data_type: 'text',
        column_type: 'enrichment',
    },
    associate: {
        name: 'AI Match',
        description: 'Match rows to rows in another worksheet using AI',
        category: 'Match & Normalize',
        column_name: 'Matched Values',
        data_type: 'text',
        column_type: 'enrichment',
    },
    product_id: {
        name: 'Match Product',
        description: 'Match a description or product code column against your product master list',
        column_name: 'Product',
        column_type: 'enrichment',
        data_type: 'object',
        category: 'Match & Normalize'
    },
};

// Icons for each category
const categoryIcons = {
    'Locations': <HomeOutlined />,
    'Aggregate': <ProfileOutlined />,
    'Transform': <FontAwesomeIcon icon={faWandMagicSparkles} />,
    'Match & Normalize': <MergeOutlined />,
};

const EnrichmentFlow = ({
    userData,
    open,
    products,
    editingEnrichmentConfigBody,
    onClose,
    onOk,
    columns,
    currentWorksheetId,
    rows
}) => {
    // If editingEnrichmentConfigBody is non-null, we are "editing" an existing enrichment
    const isEditing = !!editingEnrichmentConfigBody;

    const [selectedCategory, setSelectedCategory] = useState('Locations');
    const [selectedType, setSelectedType] = useState(null);

    // Single "input" column for cross-reference, transform, or multiple for AI match
    const [selectedColumns, setSelectedColumns] = useState([]);

    // For naming the new (destination) column
    const [newColumnName, setNewColumnName] = useState('');

    // For AI custom prompt
    const [newPrompt, setNewPrompt] = useState('');

    // For referencing other worksheets
    const [selectedWorksheet, setSelectedWorksheet] = useState(null);
    const [worksheetOptions, setWorksheetOptions] = useState([]);
    const [filteredWorksheetOptions, setFilteredWorksheetOptions] = useState([]);
    
    // We'll store `null` while fetching columns; store an array once fetched
    const [worksheetColumnOptions, setWorksheetColumnOptions] = useState(null);
    const [selectedWorksheetColumn, setSelectedWorksheetColumn] = useState(null);

    // For AI associate option
    const [leaveBlank, setLeaveBlank] = useState(true);

    // For cross-reference aggregator column
    const [crossRefAggColumn, setCrossRefAggColumn] = useState(null);
    const [crossRefAggMethod, setCrossRefAggMethod] = useState(null);

    // For the "Locations / quantity_sold" flow
    const [selectedProductsLoc, setSelectedProductsLoc] = useState(['*']);
    const [dateRangesLoc, setDateRangesLoc] = useState([]);
    const [dateRangesMeta, setDateRangesMeta] = useState([]);
    const [expectedDataSources, setExpectedDataSources] = useState([]);
    const [selectedDateRangesLoc, setSelectedDateRangesLoc] = useState([]);
    const [loadingDateRangesLoc, setLoadingDateRangesLoc] = useState(false);
    const [allowArchivedProducts, setAllowArchivedProducts] = useState(false);

    // 1) Group the config items by category
    const categories = useMemo(() => {
        const temp = {};
        Object.entries(enrichmentConfigs).forEach(([key, val]) => {
            if (!temp[val.category]) temp[val.category] = [];
            temp[val.category].push({ type: key, ...val });
        });
        return Object.entries(temp).map(([catName, items]) => ({
            catName,
            items
        }));
    }, []);

    // Helper to reset all fields
    const resetFields = (keepCategory = false) => {
        setSelectedType(null);

        if (!keepCategory) {
            setSelectedCategory('Locations');
        }

        setNewColumnName('');
        setSelectedColumns([]);
        setNewPrompt('');
        setLeaveBlank(true);
        setSelectedWorksheet(null);
        setSelectedWorksheetColumn(null);
        setFilteredWorksheetOptions(worksheetOptions);
        setCrossRefAggColumn(null);
        setCrossRefAggMethod(null);

        // Reset "Locations" fields
        setSelectedProductsLoc(['*']);
        setDateRangesLoc([]);
        setSelectedDateRangesLoc([]);
        setLoadingDateRangesLoc(false);

        // Reset "Match Product" fields
        setAllowArchivedProducts(false);
    };

    useEffect(() => {
        // set name from relevant enrichmentConfigs item
        setNewColumnName(
            editingEnrichmentConfigBody?.column_name || enrichmentConfigs[selectedType]?.column_name 
        );
    }, [selectedType]);

    // 2) If editingEnrichmentConfigBody is present, initialize from it
    useEffect(() => {
        if (!editingEnrichmentConfigBody) {
            resetFields();
            return;
        }

        const theType = editingEnrichmentConfigBody.type;
        setSelectedType(theType);

        // Get the category from our enrichmentConfigs
        const config = enrichmentConfigs[theType] || {};
        const cat = config.category || 'Aggregate';
        setSelectedCategory(cat);

        // Column name
        setNewColumnName(editingEnrichmentConfigBody.column_name || '');

        // Local columns chosen
        setSelectedColumns(editingEnrichmentConfigBody.inputCols || []);

        // AI prompt + blank check
        setNewPrompt(editingEnrichmentConfigBody.newPrompt || '');
        setLeaveBlank(
            editingEnrichmentConfigBody.leaveBlank === false
                ? false
                : true
        );

        // Worksheet ID from S3 key
        const s3Key = editingEnrichmentConfigBody.selectedWorksheetS3Key;
        if (s3Key) {
            const parts = s3Key.split('/');
            const lastPart = parts[parts.length - 1];
            const sheetId = lastPart.replace('.json', '');
            setSelectedWorksheet(sheetId);
        }

        // Worksheet column
        setSelectedWorksheetColumn(editingEnrichmentConfigBody.selectedWorksheetColumn || null);

        // aggregator
        if (theType === 'aggregate') {
            const aggCols = editingEnrichmentConfigBody.crossRefAggColumns || [];
            setCrossRefAggColumn(aggCols.length ? aggCols[0] : null);
            setCrossRefAggMethod(editingEnrichmentConfigBody.crossRefAggMethod || null);
        } else if (theType === 'quantity_sold') {
            // parse date ranges
            setSelectedDateRangesLoc(
                editingEnrichmentConfigBody.selectedDateRanges.map((d) => {
                    const parts = d.split(' - ');
                    return (
                        `${dayjs(parts[0]).format('YYYY-MM-DDTHH:mm:ss')} - ` +
                        `${dayjs(parts[1]).format('YYYY-MM-DDTHH:mm:ss')}`
                    );
                })
            );
            setSelectedProductsLoc(editingEnrichmentConfigBody.selectedProducts);
        } else if (theType === 'product_id') {
            setAllowArchivedProducts(!!editingEnrichmentConfigBody.allowArchivedProducts);
        }
    }, [editingEnrichmentConfigBody]);

    // 3) Handle user clicking OK => build final object
    const handleOk = () => {
        let selectedFolder = '';
        if (selectedWorksheet) {
            const selectedSheetData = worksheetOptions.find((o) => o.value === selectedWorksheet);
            selectedFolder = selectedSheetData?.folder || '';
        }

        // data type for output column
        const outputDataType =
            worksheetColumnOptions?.find((o) => o.value === crossRefAggColumn)?.dataType || 'text';

        // aggregator columns
        const crossRefAggColumns = crossRefAggColumn ? [crossRefAggColumn] : [];

        // If user chooses "Locations" category, gather product/dateRange states
        const locSpecificConfig =
            enrichmentConfigs[selectedType]?.category === 'Locations'
                ? {
                      selectedProductNames: selectedProductsLoc.map((p) => products.find((pr) => pr.uuid === p)?.name),
                      selectedProducts: selectedProductsLoc,
                      selectedDateRanges: selectedDateRangesLoc
                  }
                : {};

        let exportConfig = {};
        let dataTypeOverride = null;
        if (selectedType === 'quantity_sold') {
            if (selectedProductsLoc.length > 1)
                dataTypeOverride = 'object';

            const dayjsDateRanges = selectedDateRangesLoc.map((d) => {
                const parts = d.split(' - ');
                return {
                    StartTime: dayjs(parts[0]),
                    EndTime: dayjs(parts[1])
                };
            });
            const selectedProductNames = selectedProductsLoc.map((p) => [
                ...products,
                {uuid: '*', name: 'All'},
                {uuid: '-1', name: 'Unknown'}
            ].find((pr) => pr.uuid === p)?.name);

            exportConfig = {
                exportAsCols: {
                    // earliest start date
                    startDate: {
                        'name': 'Start Date',
                        'value': dayjsDateRanges.reduce(
                            (acc, cur) => (cur.StartTime < acc ? cur.StartTime : acc), dayjsDateRanges[0].StartTime
                        ).format('YYYY-MM-DD')
                    },
                    // latest end date
                    endDate: {
                        'name': 'End Date',
                        'value': dayjsDateRanges.reduce(
                            (acc, cur) => (cur.EndTime > acc ? cur.EndTime : acc), dayjsDateRanges[0].EndTime
                        ).format('YYYY-MM-DD')
                    },
                }
            };
        }

        const enrichmentConfig = {
            ...enrichmentConfigs[selectedType],
            column_name: newColumnName,
            data_type: dataTypeOverride || enrichmentConfigs[selectedType].data_type || outputDataType,
            inputCols: selectedColumns,
            selectedWorksheetS3Key: selectedFolder
                ? `${selectedFolder}/${selectedWorksheet}.json`
                : `${selectedWorksheet}.json`,
            selectedWorksheetColumn,
            newPrompt,
            exportConfig,
            leaveBlank,
            crossRefAggColumns,
            crossRefAggMethod,
            ...locSpecificConfig
        };

        if (selectedType === 'product_id') {
            enrichmentConfig.allowArchivedProducts = allowArchivedProducts;
        }

        console.log('Final enrichment config', enrichmentConfig);

        resetFields();
        onOk(selectedType, selectedColumns, enrichmentConfig, isEditing);
    };

    // 4) Compute if "Ok" button should be disabled
    const isOkDisabled = useMemo(() => {
        if (!selectedType) return true;
        const category = enrichmentConfigs[selectedType].category;

        // must have at least one selected input col, unless it's the "Locations" category
        if ((!selectedColumns.length || !selectedColumns[0]) && category !== 'Locations') {
            return true;
        }

        if (category === 'Match & Normalize') {
            if (selectedType === 'associate') {
                // require target worksheet + column
                if (!selectedWorksheet || !selectedWorksheetColumn) return true;
            }
        }

        if (category === 'Aggregate') {
            // require a target worksheet, a ref column, aggregator column, aggregator method
            if (!selectedWorksheet || !selectedWorksheetColumn) return true;
            if (!crossRefAggColumn) return true;
            if (!crossRefAggMethod) return true;
        }

        if (category === 'Locations') {
            // must have location_id in all rows
            if (!rows.length || !rows.every((r) => r.location_id)) {
                return true;
            }
            // date select must have at least one entry
            if (!selectedDateRangesLoc.length) return true;
        }

        return false;
    }, [
        selectedType,
        selectedColumns,
        selectedWorksheet,
        selectedWorksheetColumn,
        crossRefAggColumn,
        crossRefAggMethod,
        newPrompt,
        newColumnName,
        rows,
        selectedProductsLoc,
        selectedDateRangesLoc
    ]);

    // For location-based disabled reason
    const locationRowsDisabledMsg = useMemo(() => {
        if (
            selectedType &&
            enrichmentConfigs[selectedType].category === 'Locations' &&
            (!rows.length || !rows.every((r) => r.location_id))
        ) {
            return "Location enrichments only available on imported Location rows";
        }
        return "";
    }, [selectedType, rows]);

    // 5) Load list of available worksheets
    const getWorksheets = async () => {
        const body = { action: 'list_worksheets' };
        const data = await fetchWorksheetData(userData.accessToken, body);
        if (data.error) {
            console.error('Error fetching worksheets', data.error);
        } else {
            const options = (data.metadata || [])
                .filter((o) => !o.isFolder && o.id !== currentWorksheetId)
                .map((o) => ({
                    label: (o.folder ? `${o.folder} / ` : '') + o.name,
                    value: o.id,
                    folder: o.folder,
                    last_indexed_at: o.last_indexed_at
                }));
            setWorksheetOptions(options);
            setFilteredWorksheetOptions(options);
        }
    };

    // 6) Load columns from selected worksheet
    const getWorksheetColumns = async (worksheetId, folder) => {
        setWorksheetColumnOptions(null);

        const body = {
            action: 'list_worksheet_columns',
            worksheet_id: worksheetId,
            folder: folder
        };
        const data = await fetchWorksheetData(userData.accessToken, body);
        if (data.error) {
            console.error('Error fetching worksheet columns', data.error);
            setWorksheetColumnOptions([]);
        } else {
            const options = (data.cols || []).map((o) => {
                const icon = getColumnTypeIcon(o.column_type || 'text');
                return {
                    label: (
                        <span>
                            {icon} {o.name}
                        </span>
                    ),
                    value: o.key,
                    dataType: o.data_type || o.column_type || 'text'
                };
            });
            setWorksheetColumnOptions(options);
        }
    };

    const selectWorksheet = (value) => {
        setSelectedWorksheet(value);
        // reset aggregator state
        setSelectedWorksheetColumn(null);
        setCrossRefAggColumn(null);
        setCrossRefAggMethod(null);
    };

    // Aggregator method options
    const aggregatorOptions = useMemo(() => {
        if (!crossRefAggColumn || !worksheetColumnOptions) return [];
        const colObj = worksheetColumnOptions.find((o) => o.value === crossRefAggColumn);
        const dataType = colObj?.dataType || 'text';

        if (dataType === 'number') {
            return [
                { label: 'Count', value: 'count' },
                { label: 'Sum', value: 'sum' },
                { label: 'Average', value: 'average' }
            ];
        } else {
            return [
                { label: 'Select first', value: 'first' },
                { label: 'Count', value: 'count' },
                { label: 'Join with commas', value: 'join' }
            ];
        }
    }, [crossRefAggColumn, worksheetColumnOptions]);

    // 8) Fetch worksheets once userData is ready
    useEffect(() => {
        if (userData?.accessToken) {
            getWorksheets();
        }
    }, [userData]);

    // 9) If user picks new worksheet, load its columns
    useEffect(() => {
        if (!selectedWorksheet) {
            setWorksheetColumnOptions(null);
            return;
        }
        const folderOption = worksheetOptions.find((o) => o.value === selectedWorksheet);
        const folder = folderOption?.folder || '';
        getWorksheetColumns(selectedWorksheet, folder);
    }, [selectedWorksheet, worksheetOptions]);

    // 10) Build local column options
    const localColumnOptions = useMemo(() => {
        return columns
            .filter((c) => c.key !== 'rowIndex' && c.key !== 'addColumn')
            .map((c) => {
                const icon = getColumnTypeIcon(c.column_type || 'text');
                return {
                    label: (
                        <span>
                            {icon} {c.name}
                        </span>
                    ),
                    value: c.key
                };
            });
    }, [columns]);

    // For safely showing selectedWorksheetColumn
    const safeWorksheetColumnValue = useMemo(() => {
        if (!worksheetColumnOptions) return undefined;
        if (!selectedWorksheetColumn) return undefined;
        const found = worksheetColumnOptions.find((opt) => opt.value === selectedWorksheetColumn);
        return found ? found.value : undefined;
    }, [selectedWorksheetColumn, worksheetColumnOptions]);

    // For aggregator column
    const safeAggColumnValue = useMemo(() => {
        if (!worksheetColumnOptions) return undefined;
        if (!crossRefAggColumn) return undefined;
        const found = worksheetColumnOptions.find((opt) => opt.value === crossRefAggColumn);
        return found ? found.value : undefined;
    }, [crossRefAggColumn, worksheetColumnOptions]);

    // 11) For "Locations" coverage data
    useEffect(() => {
        if (enrichmentConfigs[selectedType]?.category === 'Locations') {
            setLoadingDateRangesLoc(true);
            fetchWorksheetData(userData.accessToken, {
                action: 'fetch_location_coverage',
                productIds: selectedProductsLoc.includes('All') ? [] : selectedProductsLoc
            }).then((data) => {
                const coverage = data?.covered_intervals || [];
                coverage.sort((a,b) => new Date(b.StartTime) - new Date(a.StartTime));

                setExpectedDataSources(data?.expected_data_sources || []);
                setDateRangesLoc(coverage.map((c) => `${c.StartTime} - ${c.EndTime}`));
                setDateRangesMeta(coverage);

                setLoadingDateRangesLoc(false);
            }, 700);
        }
    }, [selectedProductsLoc, selectedType]);

    // Helper to build the "(x / y)" after date range
    const buildCoverageLabel = (dr, idx) => {
        const coverageItem = dateRangesMeta[idx] || {};
        const y = expectedDataSources.length; // total expected
        if (!coverageItem.DataSources || !y) {
            return `0 / ${y} data sources`;
        }
        // how many of them appear in coverageItem.DataSources
        const presentCount = coverageItem.DataSources.filter((ds) =>
            expectedDataSources.includes(ds)
        ).length;
        return `${presentCount} / ${y} data sources`;
    };

    return (
        <Modal
            title={isEditing ? "Edit Enrichment" : "Add enriched column"}
            open={open}
            onOk={handleOk}
            onCancel={onClose}
            width={800}
            okText={
                <>
                    <PlayCircleOutlined style={{ marginRight: 5 }} />
                    {isEditing ? "Run" : "Add & Run"}
                </>
            }
            okButtonProps={{ disabled: isOkDisabled }}
        >
            {/* If location-based is disabled, show reason */}
            {locationRowsDisabledMsg && (
                <Typography.Text type="danger" style={{ marginBottom: 10, display: 'block' }}>
                    {locationRowsDisabledMsg}
                </Typography.Text>
            )}

            <Row gutter={15}>
                {/* Hide side bar if we are editing an existing enrichment */}
                {!isEditing && (
                    <Col span={8}>
                        <List
                            header={<Typography.Title level={5}>Tools</Typography.Title>}
                            bordered
                            style={{ height: '100%' }}
                            dataSource={categories}
                            renderItem={(cat) => (
                                <List.Item
                                    style={{
                                        cursor: 'pointer',
                                        backgroundColor:
                                            cat.catName === selectedCategory
                                                ? '#e6f7ff'
                                                : 'inherit'
                                    }}
                                    onClick={() => {
                                        setSelectedCategory(cat.catName);
                                        resetFields(true);
                                    }}
                                >
                                    <List.Item.Meta
                                        avatar={categoryIcons[cat.catName] || null}
                                        title={cat.catName}
                                    />
                                </List.Item>
                            )}
                        />
                    </Col>
                )}

                <Col span={isEditing ? 24 : 16}>
                    {/* If not editing, show card grid for picking an enrichment */}
                    {!isEditing && !selectedType && (
                        <Row gutter={[16, 16]}>
                            {categories
                                .find((c) => c.catName === selectedCategory)
                                ?.items.map((item) => (
                                    <Col span={12} key={item.type}>
                                        <Card
                                            hoverable
                                            title={item.name}
                                            onClick={() => setSelectedType(item.type)}
                                        >
                                            <p>{item.description}</p>
                                        </Card>
                                    </Col>
                                ))}
                        </Row>
                    )}

                    {/* ========================= AGGREGATE ========================= */}
                    {selectedType && enrichmentConfigs[selectedType].category === 'Aggregate' && (
                        <>
                            {!isEditing && (
                                <>
                                    <Typography.Text>New column name</Typography.Text>
                                    <br />
                                    <Input
                                        style={{ width: '100%', marginTop: 8 }}
                                        value={newColumnName}
                                        onChange={(e) => setNewColumnName(e.target.value)}
                                    />
                                    <br />
                                    <br />
                                </>
                            )}

                            <Typography.Title level={5} style={{ marginTop: 16 }}>
                                Setup Inputs
                            </Typography.Title>

                            <Typography.Text>Choose column from this worksheet to match row values</Typography.Text>
                            <br />
                            <Select
                                placeholder="Select a column"
                                style={{ width: '100%', marginTop: 8 }}
                                options={localColumnOptions}
                                onChange={(val) => setSelectedColumns([val])}
                                value={selectedColumns[0] || undefined}
                            />

                            <br />
                            <br />
                            <Typography.Text style={{ marginTop: 15 }}>
                                Select worksheet to cross reference against
                            </Typography.Text>
                            <br />
                            <Select
                                style={{ width: '100%', marginTop: 8 }}
                                options={worksheetOptions}
                                onChange={selectWorksheet}
                                value={selectedWorksheet || undefined}
                                placeholder="Select worksheet"
                            />

                            <br />
                            <br />
                            {selectedWorksheet && !worksheetColumnOptions && <LoadingOutlined />}

                            {selectedWorksheet && worksheetColumnOptions && (
                                <>
                                    <Typography.Text style={{ marginTop: 15 }}>
                                        Select column from "
                                        {worksheetOptions.find((o) => o.value === selectedWorksheet)?.label}
                                        " to match values against column "
                                        {columns.find((c) => c.key === selectedColumns[0])?.name}
                                        "
                                    </Typography.Text>
                                    <br />
                                    <Select
                                        style={{ width: '100%', marginTop: 8 }}
                                        options={worksheetColumnOptions.map(o => {
                                            console.log(o);
                                            if (o.dataType !== 'object') {
                                                return o;
                                            } else {
                                                return {
                                                    ...o,
                                                    disabled: true,
                                                }
                                            }
                                        })}
                                        loading={!worksheetColumnOptions}
                                        onChange={setSelectedWorksheetColumn}
                                        value={safeWorksheetColumnValue}
                                        placeholder="Select column"
                                    />

                                    <br />
                                    <br />
                                    <Typography.Text style={{ marginTop: 15 }}>
                                        Select column to aggregate values from
                                    </Typography.Text>
                                    <br />
                                    <Select
                                        placeholder="Select a column"
                                        style={{ width: '100%', marginTop: 8 }}
                                        options={worksheetColumnOptions.map(o => {
                                            console.log(o);
                                            if (o.dataType !== 'object') {
                                                return o;
                                            } else {
                                                return {
                                                    ...o,
                                                    disabled: true,
                                                }
                                            }
                                        })}
                                        loading={!worksheetColumnOptions}
                                        onChange={setCrossRefAggColumn}
                                        value={safeAggColumnValue}
                                    />

                                    <br />
                                    <br />
                                    <Typography.Text style={{ marginTop: 15 }}>
                                        Select aggregation method
                                    </Typography.Text>
                                    <br />
                                    <Select
                                        style={{ width: '100%', marginTop: 8 }}
                                        options={aggregatorOptions}
                                        onChange={(val) => setCrossRefAggMethod(val)}
                                        value={crossRefAggMethod || undefined}
                                        placeholder="Select aggregation method"
                                    />
                                </>
                            )}
                        </>
                    )}

                    {/* ========================= MATCH & NORMALIZE ========================= */}
                    {selectedType && enrichmentConfigs[selectedType].category === 'Match & Normalize' && (
                        <>
                            {!isEditing && (
                                <>
                                    <Typography.Text>New column name</Typography.Text>
                                    <br />
                                    <Input
                                        style={{ width: '100%', marginTop: 8 }}
                                        value={newColumnName}
                                        onChange={(e) => setNewColumnName(e.target.value)}
                                    />
                                    <br />
                                    <br />
                                </>
                            )}

                            <Typography.Title level={5} style={{ marginTop: 16 }}>
                                Setup Inputs
                            </Typography.Title>

                            <Typography.Text>Choose the input column(s)</Typography.Text>
                            <br />
                            {selectedColumns.map((col, idx) => (
                                <div
                                    key={idx}
                                    style={{ display: 'flex', alignItems: 'center', marginTop: 8 }}
                                >
                                    <Select
                                        placeholder="Select a column"
                                        style={{ width: '100%' }}
                                        options={localColumnOptions}
                                        onChange={(val) => {
                                            const newCols = [...selectedColumns];
                                            newCols[idx] = val;
                                            setSelectedColumns(newCols);
                                        }}
                                        value={col}
                                    />
                                    <Button
                                        type="text"
                                        icon={<DeleteOutlined />}
                                        onClick={() => {
                                            setSelectedColumns((prev) =>
                                                prev.filter((_, i) => i !== idx)
                                            );
                                        }}
                                        style={{ marginLeft: 8 }}
                                    />
                                </div>
                            ))}
                            <Button
                                type="dashed"
                                icon={<PlusOutlined />}
                                style={{ marginTop: 8 }}
                                onClick={() => setSelectedColumns((prev) => [...prev, null])}
                            >
                                Add column
                            </Button>

                            {selectedType === 'associate' && (
                                <>
                                    <br />
                                    <br />
                                    <Typography.Text style={{ marginTop: 15 }}>
                                        Select worksheet to match against
                                    </Typography.Text>
                                    <br />
                                    <Select
                                        style={{ width: '100%', marginTop: 8 }}
                                        showSearch
                                        allowClear
                                        filterOption={false}
                                        options={filteredWorksheetOptions.map((o) => ({
                                            ...o,
                                            label: o.label,
                                            disabled: !o.last_indexed_at
                                        }))}
                                        notFoundContent={!worksheetOptions ? <LoadingOutlined /> : 'No results'}
                                        onSearch={(val) => {
                                            setFilteredWorksheetOptions(
                                                worksheetOptions.filter((o) =>
                                                    o.label.toLowerCase().includes(val.toLowerCase())
                                                )
                                            );
                                        }}
                                        onClear={() => setFilteredWorksheetOptions(worksheetOptions)}
                                        onChange={selectWorksheet}
                                        value={selectedWorksheet || undefined}
                                        placeholder="Select a worksheet"
                                    />
                                    <br />
                                    <br />
                                    {selectedWorksheet && !worksheetColumnOptions && <LoadingOutlined />}

                                    {selectedWorksheet && worksheetColumnOptions && (
                                        <>
                                            <Typography.Text style={{ marginTop: 15 }}>
                                                Select column from{' '}
                                                {worksheetOptions.find((o) => o.value === selectedWorksheet)
                                                    ?.label}{' '}
                                                to pull values from
                                            </Typography.Text>
                                            <br />
                                            <Select
                                                style={{ width: '100%', marginTop: 8 }}
                                                options={worksheetColumnOptions}
                                                loading={!worksheetColumnOptions}
                                                onChange={setSelectedWorksheetColumn}
                                                value={safeWorksheetColumnValue}
                                                placeholder="Select a column"
                                            />
                                            <Collapse
                                                ghost
                                                defaultActiveKey={[]}
                                                style={{ marginTop: 16 }}
                                            >
                                                <Collapse.Panel header="Advanced Options" key="1">
                                                    <Typography.Text style={{ marginTop: 15 }}>
                                                        Row match AI prompt
                                                    </Typography.Text>
                                                    <br />
                                                    <TextArea
                                                        style={{ width: '100%', marginTop: 8 }}
                                                        placeholder="Which retail chain does this store location belong to?"
                                                        rows={2}
                                                        value={newPrompt}
                                                        onChange={(e) =>
                                                            setNewPrompt(e.target.value)
                                                        }
                                                    />
                                                    <br />
                                                    <br />
                                                    <Checkbox
                                                        checked={leaveBlank}
                                                        onChange={(e) => setLeaveBlank(e.target.checked)}
                                                    >
                                                        Leave match blank if AI unsure
                                                    </Checkbox>
                                                </Collapse.Panel>
                                            </Collapse>
                                        </>
                                    )}
                                </>
                            )}

                            {selectedType === 'product_id' && (
                                <>
                                    <br />
                                    <Checkbox
                                        style={{ marginTop: 15 }}
                                        checked={allowArchivedProducts}
                                        onChange={(e) => setAllowArchivedProducts(e.target.checked)}
                                    >
                                        Allow archived products
                                    </Checkbox>
                                </>
                            )}
                        </>
                    )}

                    {/* ========================= TRANSFORM ========================= */}
                    {selectedType && enrichmentConfigs[selectedType].category === 'Transform' && (
                        <>
                            {!isEditing && (
                                <>
                                    <Typography.Text>Destination column name</Typography.Text>
                                    <br />
                                    <Input
                                        style={{ width: '100%', marginTop: 8 }}
                                        value={newColumnName}
                                        onChange={(e) => setNewColumnName(e.target.value)}
                                    />
                                    <br />
                                    <br />
                                </>
                            )}

                            <Typography.Title level={5} style={{ marginTop: 16 }}>
                                Setup Inputs
                            </Typography.Title>

                            <Typography.Text>Select an input column to transform</Typography.Text>
                            <br />
                            <Select
                                placeholder="Select a column"
                                style={{ width: '100%', marginTop: 8 }}
                                options={localColumnOptions}
                                onChange={(val) => setSelectedColumns([val])}
                                value={selectedColumns[0] || undefined}
                            />

                            <br />
                            <br />
                            <Typography.Text>AI Prompt</Typography.Text>
                            <br />
                            <TextArea
                                style={{ width: '100%', marginTop: 8 }}
                                rows={4}
                                placeholder="Enter an AI prompt to transform the input column data..."
                                value={newPrompt}
                                onChange={(e) => setNewPrompt(e.target.value)}
                            />
                        </>
                    )}

                    {/* ========================= LOCATIONS (quantity_sold) ========================= */}
                    {selectedType && enrichmentConfigs[selectedType].category === 'Locations' && (
                        <>
                            {!isEditing && (
                                <>
                                    <Typography.Text>New column name</Typography.Text>
                                    <br />
                                    <Input
                                        style={{ width: '100%', marginTop: 8 }}
                                        value={newColumnName}
                                        onChange={(e) => setNewColumnName(e.target.value)}
                                    />
                                    <br />
                                    <br />
                                </>
                            )}

                            <Typography.Title level={5} style={{ marginTop: 16 }}>
                                Setup Inputs for Quantity Sold
                            </Typography.Title>
                            <Typography.Text>Select product(s)</Typography.Text>
                            <br />
                            <Select
                                mode="multiple"
                                style={{ width: '100%', marginTop: 8 }}
                                value={selectedProductsLoc}
                                onChange={(vals) => {
                                    if (vals.length === 0) setSelectedProductsLoc(['*']);
                                    else if (vals.includes('*') && !selectedProductsLoc.includes('*')) {
                                        setSelectedProductsLoc(['*']);
                                    } else if (
                                        selectedProductsLoc.includes('*') &&
                                        (!vals.includes('*') || vals.length > 1)
                                    ) {
                                        setSelectedProductsLoc(vals.filter((v) => v !== '*'));
                                    } else {
                                        setSelectedProductsLoc(vals);
                                    }
                                    setSelectedDateRangesLoc([]);
                                }}
                                filterOption={(input, option) =>
                                    option.label.toLowerCase().includes(input.toLowerCase())
                                }
                                options={[
                                    { label: '(All)', value: '*' },
                                    ...(products || []).map((p) => ({
                                        label: p.name,
                                        value: p.uuid
                                    }))
                                ]}
                            />
                            <br />
                            <br />
                            <Typography.Text>Sum date range(s)</Typography.Text>
                            <br />
                            {loadingDateRangesLoc ? (
                                <LoadingOutlined style={{ marginLeft: 8 }} />
                            ) : (
                                <Select
                                    mode="multiple"
                                    style={{ width: '100%', marginTop: 8 }}
                                    value={selectedDateRangesLoc}
                                    onChange={setSelectedDateRangesLoc}
                                    optionRender={(option) => (
                                        <Row>
                                            <Col flex={21}>
                                                <Typography.Text>{option.label}</Typography.Text>
                                            </Col>
                                            <Col flex='auto'>
                                            </Col>
                                            <Col flex={3}>
                                                <Typography.Text type="secondary">
                                                    {option.data.coverageText}
                                                </Typography.Text>
                                            </Col>
                                        </Row>
                                    )}
                                    options={dateRangesLoc.map((dr, dr_idx) => {
                                        const dateString = dr
                                            .split(' - ')
                                            .map((d) => dayjs(d).format('MMM D, YYYY'))
                                            .join(' - ');

                                        // Create a label with date range + coverage count
                                        const coverageText = buildCoverageLabel(dr, dr_idx);
                                        return {
                                            label: `${dateString}`,
                                            coverageText: coverageText,
                                            value: dr,
                                            data_sources: dateRangesMeta[dr_idx]['DataSources']
                                        };
                                    })}
                                />
                            )}

                            {/* Coverage list, showing each selected date range and data sources */}
                            {selectedType === 'quantity_sold' && selectedDateRangesLoc.length > 0 && (
                                <>
                                    <List
                                        style={{ marginTop: 16 }}
                                        dataSource={selectedDateRangesLoc}
                                        renderItem={(drVal) => {
                                            const idx = dateRangesLoc.indexOf(drVal);
                                            if (idx === -1) return null;

                                            const coverageItem = dateRangesMeta[idx] || {};
                                            // coverageItem => { StartTime, EndTime, Filenames, DataSources, FileLinks }

                                            return (
                                                <List.Item>
                                                    <List.Item.Meta
                                                        title={
                                                            <span>
                                                                {drVal
                                                                    .split(' - ')
                                                                    .map((d) => dayjs(d).format('MMM D, YYYY'))
                                                                    .join(' - ')}
                                                            </span>
                                                        }
                                                        description={
                                                            <List
                                                                size="small"
                                                                dataSource={expectedDataSources}
                                                                renderItem={(dsKey) => {
                                                                    const dsIndex = coverageItem.DataSources?.indexOf(
                                                                        dsKey
                                                                    );
                                                                    const isPresent = dsIndex >= 0;

                                                                    if (isPresent) {
                                                                        const fileLink =
                                                                            coverageItem.FileLinks[dsIndex];
                                                                        const fileName =
                                                                            coverageItem.Filenames[dsIndex];

                                                                        return (
                                                                            <List.Item>
                                                                                <div
                                                                                    style={{
                                                                                        width: '100%',
                                                                                        display: 'flex',
                                                                                        alignItems: 'center',
                                                                                        justifyContent: 'space-between'
                                                                                    }}
                                                                                >
                                                                                    {/* Left side: check + dsName */}
                                                                                    <div
                                                                                        style={{
                                                                                            display: 'flex',
                                                                                            alignItems: 'center'
                                                                                        }}
                                                                                    >
                                                                                        <div
                                                                                            style={{
                                                                                                width: 20,
                                                                                                textAlign: 'center',
                                                                                                marginRight: 8
                                                                                            }}
                                                                                        >
                                                                                            <CheckCircleOutlined
                                                                                                style={{ color: 'green' }}
                                                                                            />
                                                                                        </div>
                                                                                        <div>{dsKey}</div>
                                                                                    </div>
                                                                                    {/* Right side: truncated file link */}
                                                                                    <div
                                                                                        style={{
                                                                                            marginLeft: 'auto',
                                                                                            whiteSpace: 'nowrap',
                                                                                            textOverflow: 'ellipsis',
                                                                                            overflow: 'hidden',
                                                                                            maxWidth: 550,
                                                                                            textAlign: 'right'
                                                                                        }}
                                                                                    >
                                                                                        <a
                                                                                            href={fileLink}
                                                                                            target="_blank"
                                                                                            rel="noopener noreferrer"
                                                                                        >
                                                                                            <DownloadOutlined
                                                                                                style={{ marginRight: 4 }}
                                                                                            />
                                                                                            {fileName}
                                                                                        </a>
                                                                                    </div>
                                                                                </div>
                                                                            </List.Item>
                                                                        );
                                                                    }

                                                                    // Not present => make dsKey gray
                                                                    return (
                                                                        <List.Item>
                                                                            <div
                                                                                style={{
                                                                                    width: '100%',
                                                                                    display: 'flex',
                                                                                    alignItems: 'center',
                                                                                    justifyContent: 'space-between'
                                                                                }}
                                                                            >
                                                                                <div
                                                                                    style={{
                                                                                        display: 'flex',
                                                                                        alignItems: 'center'
                                                                                    }}
                                                                                >
                                                                                    <div
                                                                                        style={{
                                                                                            width: 20,
                                                                                            textAlign: 'center',
                                                                                            marginRight: 8
                                                                                        }}
                                                                                    >
                                                                                            {/* no check icon */}
                                                                                    </div>
                                                                                    <div style={{ color: 'gray' }}>
                                                                                        {dsKey}
                                                                                    </div>
                                                                                </div>
                                                                                <div
                                                                                    style={{
                                                                                        marginLeft: 'auto',
                                                                                        color: 'gray',
                                                                                        whiteSpace: 'nowrap',
                                                                                        textOverflow: 'ellipsis',
                                                                                        overflow: 'hidden',
                                                                                        maxWidth: 550,
                                                                                        textAlign: 'right'
                                                                                    }}
                                                                                >
                                                                                    no location data for this date range
                                                                                </div>
                                                                            </div>
                                                                        </List.Item>
                                                                    );
                                                                }}
                                                            />
                                                        }
                                                    />
                                                </List.Item>
                                            );
                                        }}
                                    />
                                </>
                            )}
                        </>
                    )}
                </Col>
            </Row>
        </Modal>
    );
};

export {EnrichmentFlow, enrichmentConfigs};